import React from "react";
import { useSelector } from "react-redux";
import { Progress } from "reactstrap";



export default function Header({ name}) {
  const post = useSelector(state => state.addclaim.post);
  const isSubmitting = useSelector(state => state.addclaim.isSubmitting);
  let index = 1;
  const step = post.step;
  if (step === "label_ocr") {
    index = 1;
  } else if (step === "validate_label") {
    index = 2;
  } else if ((/^meta_ocr_\d{1,3}$/).test(step)) {
    index = parseInt( step.split(/[_ ]+/).pop() ) + 2;
  } else if (step === "validate_meta") {
    index = 5;
  }

  return (
    <div className="pb-4">
      <h3 >
        <small className="text-muted">Add claim</small><br></br>
        <span className="text-capitalize">{name}</span>
      </h3>
      <Progress animated={isSubmitting} value={100 * index / 5} ></Progress>
    </div>
  );
}

