/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
// add these
import { Col, Container, Row } from "reactstrap";
import { ROOT_URL } from "../../../constants/urls";
import { getTokenFromCookie } from "./actions";

export default function Login() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    if (params.action && params.action === "after-oauth") {
      dispatch(getTokenFromCookie(navigate))
    }
  }, [dispatch, navigate, params.action])

  if (params.action && params.action === "after-oauth") {
    return (<Loading text="Authenticating" />)
  }
  return (
    <Container>
      <Row className="justify-content-center align-items-center" css={css`min-height: 80vh;`}>
        <Col className="col col-sm-4">
          <a className="btn btn-primary btn-block" href={ROOT_URL + "oauth2/login"}>Login with Microsoft</a>

          <div className="pt-5"><a href={ROOT_URL + "oauth2/logout"}  >Automatically logged in with the wrong account? Click here to log in with another Microsoft account.</a></div>
        </Col>
      </Row>
    </Container>
  )
}
function Loading({ text, action }) {
  return (
    <div className="page-wrap d-flex flex-row align-items-center py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            {text &&
              <div className="mb-4 lead">{text}</div>
            }
            <div className="text-center py-5 opacity-80">
              <div className="spinner-border" role="status">
                <span className="sr-only">{action}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


Loading.defaultProps = {
  text: false,
  action: "Loading"
}
