import store from '../../store';
import { CREATE, DELETE, GET, LIST, UPDATE } from './actionsTypes';

const endpoint = '/lely-items/'
export function clearFilter(){
  return {
    type: LIST.FILTER,
    payload: {}
  }
}
export function listItems(filter = {}) {
  const state = store.getState();
  filter = { ...state.items.filter, ...filter };
  return function (dispatch) {
    dispatch({
      type: LIST.FILTER,
      payload: filter
    })
    dispatch({
      types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
      payload: {
        request: {
          params: filter,
          method: 'GET',
          url: endpoint
        }
      }
    }
    )
  }
}
export function getItem(item_id) {
  return {
    types: [GET.LOADING, GET.SUCCESS, GET.ERROR],
    payload: {
      request: {
        method: 'GET',
        url: endpoint +item_id + '/'
      }
    }
  }
}
export function createItem(item) {
  return {
    types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
    payload: {
      request: {
        data: item,
        method: 'POST',
        url: endpoint
      }
    }
  }
}

export function updateItem(item) {
  return {
    types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
    payload: {
      request: {
        data: item,
        method: 'PUT',
        url: endpoint + item.item_id + '/'
      }
    }
  }
}

export function updateItemTemp(item){
  return function(dispatch){
    dispatch({
       type: UPDATE.SUCCESS,
       payload: {data: item}
    })
  }
}
export function deleteItem(item_id) {
  return {
    types: [DELETE.LOADING, DELETE.SUCCESS, DELETE.ERROR],
    payload: {
      request: {
        method: 'DELETE',
        item_id: item_id,
        url: endpoint + item_id + '/'
      }
    }
  }
}
