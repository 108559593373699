import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import {
  Button, Col, FormGroup, FormText, Input, Label, Modal, ModalBody,
  ModalFooter, ModalHeader, Row
} from "reactstrap";
import { bindActionCreators } from 'redux';
import { deleteSignal, selectSignal } from "../../../../../actions/Signal/actions";
import ItemSelect from "../../../../Forms/ItemSelect";
import RadioButton from '../../../../Forms/RadioButton.js';
import { updateItem } from "../../actions";

class WarrantyRequestPanel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      feedback: props.current.attributes.feedback ? props.current.attributes.feedback.output : '',
      status: props.current.status,
      modal: {
        front: false,
        back: false,
        confirm: false,
        item_number: false
      },
      locked: props.current.status === 'closed-declined' || props.current.status === 'closed-accepted',
      reasons: [
        {
          key: 'C80',
          text: 'C80. Out of warranty. Claim rejected.',
          checked: props.current.attributes.C80 ? props.current.attributes.C80.output : false
        },
        {
          key: 'C50',
          text: 'C50. Item didn\'t contain the item label. Claim rejected. It is removed from credit.',
          checked: props.current.attributes.C50 ? props.current.attributes.C50.output : false
        },
        {
          key: 'C55',
          text: 'C55. Serial number is unreadable. Claim rejected. Item is removed from credit.',
          checked: props.current.attributes.C55 ? props.current.attributes.C55.output : false
        },
        {
          key: 'C65',
          text: 'C65. Damaged by user according to our specification. Claim rejected. Item is removed from credit.',
          checked: props.current.attributes.C65 ? props.current.attributes.C65.output : false
        },
        {
          key: 'user-input-claimed-before',
          text: 'Serial number is claimed before.',
          checked: props.current.attributes.C65 || props.current.attributes.is_claimed_before.input > 0 ? true : false
        }
      ],
      other_decline: props.current.attributes.other_decline ? props.current.attributes.other_decline.output : ''
    };

  }
  updateItem() {
    this.props.updateItem(this.props.current);
  }
  close() {
    this.props.setActiveItem({});
  }
  updateStatus(value) {
    var { current } = this.props;
    this.setState({ status: value })
    current.status = value;
    if ((current.status === 'closed-accepted') && (current.attributes.proposed_status.input === current.status || current.attributes.proposed_status.input === 'open-review')) {
      this.updateItem();
      this.close();
    }
  }

  updateFeedback(value) {
    this.setState({ feedback: value });
    var { current } = this.props;
    current.attributes.feedback = { output: value };
  }
  canSubmit() {
    var { current } = this.props;
    if ((current.status === 'closed-accepted' || current.status === 'closed-declined') && current.attributes.proposed_status.input !== current.status && current.attributes.proposed_status.input !== 'open-review' && this.state.feedback.length < 15) {
      return false;
    }

    return true;

  }
  submitOutput() {
    this.updateItem();
    this.close();

  }
  getButtons() {
    var { current } = this.props;
    return (
      <div>
        <Row className="py-3 no-gutters">
          <Col xs="1" className="">
            <h4>
              <FontAwesomeIcon className={current.attributes.within_warranty.input === 'True' ? 'text-success' : 'text-danger'} icon={['far', current.attributes.within_warranty.input === 'True' ? 'check' : 'times']} />
            </h4>
          </Col>
          <Col>
            {current.attributes.warranty_reason.input}
          </Col>
        </Row>
        <Row className="py-3 no-gutters">
          <Col xs="1" className="">
            <h4>
              <FontAwesomeIcon className={current.attributes.is_claimed_before.input > 0 ? 'text-danger' : 'text-success'} icon={['far', current.attributes.is_claimed_before.input > 0 ? 'times' : 'check']} />
            </h4>
          </Col>
          <Col>
            {current.attributes.is_claimed_reason.input}
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            <RadioButton name="reserve-choice" disabled={this.state.locked} current={this.state.status} value="closed-accepted" text="Accept warranty claim" color="success" onChange={(value) => { this.updateStatus(value); }} />
            <RadioButton name="reserve-choice" disabled={this.state.locked} current={this.state.status} value="closed-declined" text="Deny warranty claim" color="danger" onChange={(value) => { this.updateStatus(value); }} className="ml-2" />
            {this.state.locked &&
              <Button color="warning" className="ml-2 align-top text-white" onClick={() => this.setState({ locked: false })}>
                <FontAwesomeIcon icon={['far', 'unlock-alt']} />
              </Button>
            }
            <Button className="ml-2 align-top text-white" onClick={() => this.toggleModal('item_number') } ><FontAwesomeIcon icon={['far', 'pencil']} /> {current.item_number}</Button>

          </Col>
        </Row>
        <UpdateItemNumberModal   toggleModal={() => this.toggleModal('item_number')} updateItem={(current) => this.props.updateItem(current)} current={current} isOpen={this.state.modal.item_number}  />
      </div>
    )
  }
  delete(signal_id) {
    this.props.deleteItem(signal_id);
    this.close();
  }
  getDeleteButton() {
    var { current } = this.props;
    if (current.status !== 'open') {
      return null;
    }
    return (
      <Row className="pb-3">
        <Col>
          <Button color="danger" onClick={() => { this.toggleModal('confirm'); }} >Delete</Button>
        </Col>
        {this.renderConfirmModal()}
      </Row>
    )
  }

  getMetaField(requested_meta) {
    var { attributes } = this.props.current;
    var { name, picture_only } = { ...requested_meta };
    if (picture_only){
      return  (
        <h5 className="text-muted">
          {name}
        </h5>
      )
    }
    var is_scanned = picture_only ? false :  (attributes['manual_' + name]?.input || '') === '';
    var className = is_scanned ? 'text-muted' : 'text-warning';
    var title = is_scanned ? 'Scanned ' + name : 'Manual ' + name;
    var value = is_scanned ? attributes['scanned_' + name]?.input : attributes['manual_' + name]?.input;
    return (
      <h5>
        <small className={className}>{title}</small><br></br>
        {value} {!picture_only && !is_scanned && <FontAwesomeIcon className={is_scanned ? 'text-success' : 'text-warning'} icon={['far', is_scanned ? 'check' : 'exclamation-triangle']} />}
      </h5>
    )
  }

  render() {

    return (<div className="">
      <h3 className="text-uppercase">Warranty check Article</h3>
      <Row className="pb-3 align-items-end">
        {this.renderRequestedMeta()}
      </Row>
      {this.renderActions()}

      {this.renderModals()}

    </div>);
  }
  renderActions() {
    var { user, current } = this.props;
    if (!user.center.is_international && !user.is_lna_superuser) {
      if (current.status === 'open') {
        return this.getDeleteButton();
      } else {
        return this.renderDeclinedReasons();
      }
    }
    return (<div>
      {this.getButtons()}
      {this.renderExplanation()}
      {this.renderDeclineReason()}
      {this.renderDeclineReasonOpenField()}
      {this.renderSubmit()}
    </div>
    )
  }



  renderRequestedMeta() {
    var { current } = this.props;
    if (typeof current.item.requested_meta === 'undefined') {
      return null;
    }
    return current.item.requested_meta.map(i => {
      return <Col key={i.item_meta_id} sm="6" >
        {this.getMetaField(i)}
        {current.images && current.images[i.side] && (current.images[i.side].base64 || current.images[i.side].url) &&
          <img className="d-block w-100" alt={"Item " + i.side} onClick={() => this.toggleModal(i.side)} src={current.images[i.side].url ? current.images[i.side].url : current.images[i.side].base64} />
        }
        {current.attributes[i.name + '_edited_by'] && <span className="text-muted text-sm">Last edited by <b>{current.attributes[i.name + '_edited_by'].input}</b> on {current.attributes[i.name + '_edited_on'].input}</span>}
      </Col>
    });
  }

  renderModals() {
    var { current, user } = this.props;
    if (!current.images) {
      return null;
    }

    return current.item.requested_meta.filter(i => !i.picture_only).map((i, index) => <RequestedMetaModal key={index} is_international={user.center.is_international || user.is_lna_superuser} requested_meta={i} toggleModal={(side) => this.toggleModal(side)} updateItem={(current) => this.props.updateItem(current)} current={current} isOpen={this.state.modal[i.side]} />);
  }
  renderConfirmModal() {
    var { current } = this.props;
    return (
      <Modal isOpen={this.state.modal['confirm']} toggle={() => this.toggleModal('confirm')} className="modal-lg">
        <ModalHeader toggle={() => this.toggleModal('confirm')}>{'Confirm'}</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this claim?  You can only delete unhandled claims.
      </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => { this.delete(current.signal_id); }} >Delete</Button>
          <Button color="secondary" onClick={() => this.toggleModal('confirm')}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )

  }
  toggleModal(side) {
    var modal = this.state.modal;
    modal[side] = !modal[side];
    this.setState({
      modal: modal
    })
  }
  renderDeclineReason() {
    var { current } = this.props;
    if (current.status !== 'closed-declined') {
      return null;
    }

    return this.state.reasons.map(i => {
      return (
        <Row key={i.key} >
          <Col>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" checked={i.checked} onChange={(e) => { this.updateReason(i, e.target.value) }} />{' '}
                {i.text}
              </Label>
            </FormGroup>
          </Col>
        </Row>
      )
    });
  }
  renderDeclinedReasons() {
    var { current } = this.props;
    if (current.status !== 'closed-declined') {
      return null;
    }

    var reasons = this.state.reasons;
    if (this.state.other_decline) {
      reasons.push({
        key: 'other',
        text: this.state.other_decline,
        checked: true
      });
    }
    return reasons.map(i => {
      if (!i.checked) {
        return null;
      }
      return (
        <Row key={i.key} >
          <Col>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" readOnly checked />{' '}
                {i.text}
              </Label>
            </FormGroup>
          </Col>
        </Row>
      )
    });
  }
  updateReason(reason, value) {
    var { current } = this.props;
    var reasons = this.state.reasons;
    var foundIndex = reasons.findIndex(x => x.key === reason.key);
    reasons[foundIndex].checked = !reasons[foundIndex].checked;
    current.attributes[reason.key] = { output: reasons[foundIndex].checked };
    this.setState({ reasons: reasons });
  }
  renderDeclineReasonOpenField() {
    var { current } = this.props;
    if (current.status !== 'closed-declined') {
      return null;
    }
    return (
      <Input id="other_decline" type="textarea" placeholder="Other..." disabled={this.state.locked} className="" value={this.state.other_decline} onChange={(e) => {
        this.updateOtherDecline(e.target.value)
      }}></Input>
    );
  }
  updateOtherDecline(value) {
    this.setState({ other_decline: value });
    var { current } = this.props;
    current.attributes.other_decline = { output: value };
  }
  renderExplanation() {
    var { current } = this.props;
    var do_render = (this.state.status === 'closed-accepted' || this.state.status === 'closed-declined') && current.attributes.proposed_status.input !== this.state.status && current.attributes.proposed_status.input !== 'open-review';
    if (!do_render) {
      return null;
    }

    return (<div className="py-3">
      <Input id="feedback" type="textarea" disabled={this.state.locked} placeholder="Please elaborate why you choose to differ from the proposed status" className="" value={this.state.feedback} onChange={(e) => {
        this.updateFeedback(e.target.value)
      }}></Input>
      <FormText color={this.state['feedback'].length > 15 ? 'success' : 'muted'}>
        Use at least 15 characters
              </FormText>
    </div>);
  }

  renderSubmit() {
    var { current } = this.props;
    var do_render = (this.state.status === 'closed-accepted' || this.state.status === 'closed-declined') && current.attributes.proposed_status.input !== current.status && current.attributes.proposed_status.input !== 'open-review';
    var do_render_2 = this.state.status === 'closed-declined';
    if (!do_render && !do_render_2) {
      return null;
    }
    return (
      <div className="py-3">
        <Button color="outline-secondary" disabled={!this.canSubmit()} onClick={() => this.submitOutput()}>Submit</Button>
        {this.state.locked &&
          <Button color="warning" className="ml-2" onClick={() => this.setState({ locked: false })}>
            <FontAwesomeIcon icon={['far', 'unlock-alt']} />
          </Button>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    // activeItem: state.inbox.activeItem,
    current: state.signals.current,
    user: state.auth.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setActiveItem: bindActionCreators(selectSignal, dispatch),
    updateItem: bindActionCreators(updateItem, dispatch),
    deleteItem: bindActionCreators(deleteSignal, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WarrantyRequestPanel);


function RequestedMetaModal({ current, requested_meta, toggleModal, isOpen, updateItem, is_international }) {
  var { name, side } = requested_meta;
  var { attributes } = current;
  var is_scanned = (attributes['manual_' + name]?.input || '') === '';
  var orig_value = is_scanned ? attributes['scanned_' + name]?.input : attributes['manual_' + name]?.input;
  var [value, setValue] = useState(orig_value)

  var className = is_scanned ? 'text-muted' : 'text-warning';
  var title = is_scanned ? 'Scanned ' + name : 'Manual ' + name;

  const submit = () => {
    current.attributes['manual_' + name].output = value;
    updateItem(current);
    toggleModal(side)
  }

  if ( !current.images[side] ){
    return null
  }
  return (
    <Modal key={current.images[side].image_id} isOpen={isOpen} toggle={() => toggleModal(side)} className="modal-lg">
      <ModalHeader toggle={() => toggleModal(side)}>
        <small className={className}>{title}</small><br></br>
        {orig_value} {!is_scanned && <FontAwesomeIcon className={is_scanned ? 'text-success' : 'text-warning'} icon={['far', is_scanned ? 'check' : 'exclamation-triangle']} />}</ModalHeader>
      <ModalBody>
        <img className="d-block w-100" alt={`Item $side`} onClick={() => toggleModal(side)} src={current.images[side].url ? current.images[side].url : current.images[side].base64} />
        {is_international &&
          <div className="pt-2">
            <Input type="text" value={value} onChange={(e) => setValue(e.target.value)}></Input>
          </div>
        }
      </ModalBody>
      <ModalFooter>
        {is_international &&
          <Button color="primary" onClick={() => submit()}  >Change {name}</Button>
        }
        <Button color="secondary" onClick={() => toggleModal(side)}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}


function UpdateItemNumberModal({ current, toggleModal, isOpen, updateItem }) {

  var [value, setValue] = useState(current.item_number)

  const submit = () => {
    current.item_number = value;
    updateItem(current);
    toggleModal('item_number')

  }

  return (
    <Modal  isOpen={isOpen} toggle={() => toggleModal('item_number')} className="modal-lg">
      <ModalHeader toggle={() => toggleModal('item_number')}>Current number: {current.item_number}</ModalHeader>
      <ModalBody>
        <ItemSelect
          label="New item number"
          name="item_number"
          id="item_number"
          onChange={(key, value) => setValue(value.value)  }
          value={value}
        />
      </ModalBody>
      <ModalFooter>
          <Button color="primary" onClick={() => submit()}  >Change item</Button>
        <Button color="secondary" onClick={() => toggleModal('item_number')}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}