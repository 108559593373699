import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../img/logo.svg";
import { logoutUser } from "./scenes/Auth/actions";

class Header extends Component {

  static propTypes = {
    authenticated: PropTypes.bool,
    logoutUser: PropTypes.func.isRequired
  };

  logoutHandler() {
    this.props.logoutUser();
  }
  renderLinks() {
    const { user, authenticated } = this.props;
    if (authenticated) {
      return ([
        (user.is_lely_superuser || user.is_lna_superuser) && <li className="nav-item" key="claims">
          <Link className="nav-link" to="/">Claims</Link>
        </li>,
        (user.is_lely_superuser || user.is_lna_superuser) && <li className="nav-item" key="admin">
          <Link className="nav-link" to={user.is_lely_superuser ? "/_admin/items" : "/_admin/add-wr-number"}>Admin</Link>
        </li>,
        <li className="nav-item" key="logout">
          <span className="nav-link btn-link" onClick={e => this.logoutHandler(e)} href="#">Logout</span>
        </li>
      ]);

    } else {
      return ([<li className="nav-item" key="login">
        <Link className="nav-link" to="/login">Login</Link>
      </li>
      ]);
    }
  }
  renderViews() {
    const { authenticated } = this.props;
    if (authenticated) {
      return (
        <div className="navbar d-none ">
          <span className="h3 header-progress text-uppercase font-weight-bold mb-0">
            <span className={"header-progress__link " + (this.props.currentView === "signal" ? "text-primary" : "")} >
              <span className="header-progress__signal">Signal <FontAwesomeIcon icon={["far", "chevron-right"]} />
              </span>
            </span>
            <span className={"header-progress__link " + (this.props.currentView === "action" ? "text-primary" : "")} >
              <span className="header-progress__action mx-3">Action <FontAwesomeIcon icon={["far", "chevron-right"]} />
              </span>
            </span>
            <span className={"header-progress__link " + (this.props.currentView === "impact" ? "text-primary" : "")} >
              <span className="header-progress__impact">Learn</span>
            </span>
          </span>
        </div>
      )
    }
  }
  render() {

    return (
      <nav className="navbar navbar-expand-sm bg-light static-top">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="" width="40px" height="40px" />
          </Link>
          {this.renderViews()}
          <ul className="navbar-nav text-right">
            {this.renderLinks()}
          </ul>
        </div>
      </nav>
    )
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
    currentView: state.inbox.currentView,
  }
}
export default connect(mapStateToProps, { logoutUser })(Header);
