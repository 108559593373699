import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faChevronDown, faChevronRight, faChevronUp, faExclamationTriangle, faEye, faFilter, faInfoCircle, faPencil, faPlus, faRedo, faSearch, faTimes, faTrash, faUnlockAlt, faVideo } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Notifs } from "redux-notifications"; // can be removed? i favour of toast
import store from "./../store";
import MainContent from "./MainContent";

library.add(faChevronRight, faPlus, faInfoCircle, faCheck, faTimes, faChevronUp, faChevronDown, faPencil, faFilter, faEye, faRedo, faVideo, faExclamationTriangle, faUnlockAlt, faTrash, faSearch);


function App() {

    return (
        <div className="wrapper">
            <Notifs store={store} />
            <ToastContainer limit={4} />
            <Router >
                <MainContent />
            </Router>
        </div>
    );
}
export default App;


