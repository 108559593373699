/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Col, Input, InputGroup, InputGroupAddon, Row, Table } from "reactstrap";
import { listSignals, updateSignalDetailsBulk } from "../../../../actions/Signal/actions";
import { Loading } from "../../../Helper/Loading";
import Pagination from "../../../Helper/Pagination";
import CenterSearch from "../../Inbox/components/CenterSearch";

const reducer = (state, { action, key, value, signal_id, range_index }) => {
    // console.log(state, action, key, value, signal_id, range_index)
    switch (action) {
        // case 'update': return { ...state, outputs: { ...state.outputs, [key]: value } };

        case "toggle":
            const index = state.items.indexOf(signal_id);
            if (index > -1) {
                state.items.splice(index, 1);
                return { ...state, items: [...state.items] }
            } else {
                return { ...state, items: [...state.items, signal_id] }
            }
        case "set_range":
            return { ...state, range_index };
        case "set_items":
            return { ...state, items: [signal_id] };
        case "clear_items":
            return { ...state, items: [] };
        case "add_item":
            if (state.items.includes(signal_id)) {
                return state;
            }
            return { ...state, items: [...state.items, signal_id] }
        default: throw new Error("Unexpected action");
    }
};

const initialState = {
    outputs: {},
    items: [],
    range_index: false
}

export default function AddWRNumber() {
    const dispatch = useDispatch();
    const { is_updating, items, isLoading } = useSelector(state => state.signals)
    const user = useSelector(state => state.auth.user)
    const filter = useSelector(state => state.signals.filter)
    const [form, dispatchForm] = useReducer(reducer, initialState);
    const [warranty_request, set_warranty_request] = useState("");

    const [dirty, setDirty] = useState({});
    const timer = useRef(null);

    useEffect(() => {
        // useRef value stored in .current property
        timer.current = setTimeout(() => dispatch(listSignals(dirty)), 330);

        // clear on component unmount
        return () => {
            clearInterval(timer.current);
        };
    }, [dirty, dispatch]);



    // loading signals
    useEffect(() => {
        dispatch(listSignals({ user__center__is_lna: true, blank_warranty_request: 1, limit: 100 }));
        // dispatch
    }, [dispatch])

    const setFilter = (data) => dispatch(listSignals({ blank_warranty_request: 1, ...data }))

    // const loadAll = () => dispatch(listSignals({
    //     ...filter,
    //     offset: 0,
    //     limit: 1 + items.count,
    // }))

    //  selecting ranges
    const clickHandler = (event, signal_id, index) => {

        if (form.range_index !== false) {
            const lowerIndex = Math.min(form.range_index, index)
            const upperIndex = Math.max(form.range_index, index)
            items.results.forEach(({ signal_id }, _index) => {
                if (_index >= lowerIndex && _index <= upperIndex) {
                    dispatchForm({ action: "add_item", signal_id })
                }
            })
            dispatchForm({ action: "set_range", range_index: false })
        } else if (event.shiftKey) {
            dispatchForm({ action: "set_range", range_index: index })
            dispatchForm({ action: "add_item", signal_id })
        } else if (event.ctrlKey) {
            dispatchForm({ action: "toggle", signal_id })
        } else {
            dispatchForm({ action: "set_items", signal_id })
        }
    }

    const selectAll = () => {
        if (form.items.length > 0) {
            dispatchForm({ action: "clear_items" })
        }
        items.results.forEach(({ signal_id }, _index) => {
            dispatchForm({ action: "add_item", signal_id })
        })

    }

    const submit = () => {
        dispatch(updateSignalDetailsBulk({ signal_ids: form.items, warranty_request })).then(response => dispatch(listSignals({})))
    }
    // change pagination
    const onPageChanged = data => {
        const { currentPage, pageLimit } = data;

        const offset = (currentPage - 1) * pageLimit;
        // filter.offset = offset;
        dispatch(listSignals({ offset: offset, currentPage: currentPage }));
    };

    const updateLimit = (limit) => {
        dispatch(listSignals({ offset: 0, limit: limit }));
    }
    const changeOrder = (order) => {
        dispatch(listSignals({ ordering: order }));
    }

    if (!items) {
        return (<Loading></Loading>);
    }


    return (
        <div>
            <div className="d-flex justify-content-start">
                <div>
                    <InputGroup>
                        <CenterSearch label={false} filter={filter} setFilter={setFilter} />
                    </InputGroup>
                </div>
                <div className="pl-2">
                    <Input type="text" value={filter.created} onChange={(e) => setDirty({ created: e.target.value })} placeholder="YYYY-MM-DD"></Input>
                </div>
                <div className="px-2">
                    <InputGroup>
                        <Input placeholder="Search a number" value={filter.a_number} onChange={(e) => setDirty({ "a_number": e.target.value })} />
                        <InputGroupAddon addonType="append">
                            <Button> <FontAwesomeIcon icon={["far", "search"]} /></Button>
                        </InputGroupAddon>
                    </InputGroup>
                </div>
                <div>
                    <Button color={filter.blank_warranty_request ? "success" : "light"} outline={!filter.blank_warranty_request} onClick={() => setFilter({ "blank_warranty_request": filter.blank_warranty_request ? 0 : 1 })}>Without WR <FontAwesomeIcon icon={["far", "filter"]} /></Button>
                </div>

            </div>
            <div className="py-4 d-flex">
                <div className="pr-2" >
                    <Button disabled={items.results.length === 0} onClick={() => form.items.length < items.results.length ? selectAll() : dispatchForm({ action: "clear_items" })} color="light">{form.items.length < items.results.length ? "Select all" : "Deselect all"} </Button>
                </div>
                <div>
                    <InputGroup>
                        <Input placeholder="WR number" value={warranty_request} onChange={(e) => set_warranty_request(e.target.value)} />
                        <InputGroupAddon addonType="append">
                            <Button onClick={() => submit()} disabled={form.items.length === 0 || is_updating} color="primary">Submit {form.items.length}</Button>
                        </InputGroupAddon>
                    </InputGroup>
                </div>
            </div>
            {items.results.length === 0 &&
                <Alert color="warning">No claims found.</Alert>
            }
            {items.results.length > 0 &&
                <div css={css`font-size: 90%;`}>
                    <Table size="sm" responsive>
                        <thead>
                            <SignalTableHeader user={user} changeOrder={changeOrder} />
                        </thead>
                        <tbody className="signals">
                            {items.results.map((i, index) => <tr key={i.signal_id} onClick={(e) => clickHandler(e, i.signal_id, index)} className={(form.items.includes(i.signal_id) ? "bg-primary  text-white" : "bg-transparent")} >
                                <td>{i.signal_id}</td>
                                <td>{i.date}</td>
                                <td>{i.user.center.city || "-"}</td>
                                <td> {i.item_number}</td>
                                <td> {i.a_number}</td>
                                <td> {i.warranty_request || "-"}</td>
                            </tr>)}
                        </tbody>
                    </Table>

                    {isLoading && <Loading></Loading>}
                    <Row className={"align-items-center py-4 " + (isLoading && "d-none")}>
                        <Col md="4" className="py-2">
                            <Pagination
                                totalRecords={items.count}
                                pageLimit={filter.limit}
                                pageNeighbours={1}
                                onPageChanged={onPageChanged}
                            />
                        </Col>
                        <Col md="4" className="text-center py-2">
                            <small>
                                {items.results.length} of {items.count} results
                            </small>
                        </Col>
                        <Col md="4" className="text-center text-md-right col-sm py-2">
                            <div className="btn-group-sm" role="group" aria-label="limit">
                                {[25, 50, 100].map(i => {
                                    return (<button key={i} type="button" onClick={() => updateLimit(i)} className={"btn btn-outline-dark " + (i === filter.limit ? "active" : "")}>{i}</button>)
                                })}
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        </div>
    );

}




function SignalTableHeader({ changeOrder }) {
    return (
        <tr>
            <TableHeaderCel slug={"signal_id"} width="30px" changeOrder={changeOrder}>Signal id</TableHeaderCel>
            <TableHeaderCel slug={"date"} width="110px" className="text-left pl-1" changeOrder={changeOrder}>Date</TableHeaderCel>
            <TableHeaderCel slug={"user__center__name"} className="text-left pl-1" changeOrder={changeOrder}>Center</TableHeaderCel>
            <TableHeaderCel slug={"item_number"} className="mr-auto text-truncate" width="100px" changeOrder={changeOrder}>Item number</TableHeaderCel>
            <TableHeaderCel slug={"a_number"} className="mr-auto text-truncate" width="100px" changeOrder={changeOrder}>A number</TableHeaderCel>
            <TableHeaderCel slug={"warranty_request"} className="mr-auto text-truncate" width="100px" changeOrder={changeOrder}>Warranty Request</TableHeaderCel>
            {/* <TableHeaderCel slug={'status'} width="310px" className="text-center" changeOrder={changeOrder}>Status</TableHeaderCel> */}
            {/* <th css={css`width: 200px;`}>WR number</th> */}
        </tr>

    )
}
function TableHeaderCel(props) {
    const filter = useSelector(state => state.signals.filter)
    const { changeOrder, slug, children, width, className } = props;

    let action = slug;
    let icon = false;
    if (filter.ordering === slug) {
        action = "-" + slug;
        icon = "chevron-up";
    } else if (filter.ordering === "-" + slug) {
        action = slug;
        icon = "chevron-down";
    }
    return (
        <th css={css`width: ${width}; position:relative;`} className={className}>
            <span className="text-truncate" onClick={() => changeOrder(action)}>{children}</span>
            {icon && <span className="pl-1" onClick={() => changeOrder("")} css={css`position:absolute; top: 50%; transform: translateY(-50%);z-index:99;`} >
                <span className="badge badge-info badge-pill"><FontAwesomeIcon icon={["far", icon]} /></span>
            </span>}
        </th>
    )

}
TableHeaderCel.defaultProps = {
    width: "110px",
    className: "text-left",
}

