import React from 'react';
import {Container,  Row,  Col,} from "reactstrap";
const Box = (props) => {
  return (
    <Container className="py-3">
        <Row className="no-gutters justify-content-center">
          <Col sm="12" md="9" lg="6">
            {props.children}
          </Col>
        </Row>
  </Container>
  );
};

export default Box;
