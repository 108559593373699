import axios from "axios";
import apiClient from "../../../constants/apiClient";
import { AuthUrls, ROOT_URL } from "../../../constants/urls";
export function authLogin(tokens) {
  return {
    type: "LOGIN",
    payload: tokens
  };
}


export function getTokenFromCookie(navigate) {
  return function action(dispatch) {

    const loginUrl = AuthUrls.GET_FROM_SESSION;
    return axios({
      method: "get",
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true,
      credentials: 'include',
      crossDomain: true,
      url: loginUrl
    }).then((response) => {
      // If request is good...
      // Update state to indicate user is authenticated
      dispatch(authLogin(response.data));
      localStorage.setItem("token", response.data.access);
      localStorage.setItem("refresh_token", response.data.refresh);

      // redirect to the route '/'
      navigate("/");
    }).catch((error) => console.log(error));
  }
};

export function logoutUser() {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
  window.location.href = ROOT_URL + 'oauth2/logout';
  return {
    type: "LOGOUT"
  };
}

export function softLogoutUser() {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
  window.location.href = '/login'
  return {
    type: "LOGOUT"
  };
}


export function isUserGroupMember(user, group) {

  if (user.groups) {
    for (var i = 0; i < user.groups.length; i++) {
      if (user.groups[i].name === group) {
        return true;
      }
    }
  }
  return false;
}


function setUserProfile(payload) {
  return {
    type: "USER",
    payload: payload
  };
}

export function getUser(dispatch) {
  return function (dispatch) {
    apiClient.get(AuthUrls.USER).then(response => {
      dispatch(setUserProfile(response.data))
    });
  };
}

