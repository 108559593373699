import React, { Component } from 'react';
import Select from 'react-select';
import { getTheme } from "../../constants/SelectTheme";


class SelectWrapper extends Component {

  static defaultProps = {
    disabled: false,
    isClearable: true,
    search: true,
    showLabel: true,
    showError: true,
    onBlur: () => {},
    mapOptions: (item) => { return { label: item.name, value: item.id } },
    getItems: () => [],
    defaultValue: null,
    value: null,
  }
  handleChange = value => {
      var {onChange, name} = this.props;
      // this is going to call setFieldValue and manually update values.topcis
      onChange(name, value);
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    var {onBlur, name} = this.props;

    // this is going to call setFieldValue and manually update values.topcis
    onBlur(name, true);
  };

  componentDidMount() {
    const {getItems} = this.props;
    getItems();
  }
  getOptions() {
    const { items, mapOptions } = this.props;
    if (!items) {
      return [];
    }
    if(items.results){
      return items.results.map(mapOptions);
    }

    return items.map(mapOptions);
  }
  fromValueToObject(value){
    const { items} = this.props;
    if ( typeof value ===  'object' && value.value){
      return value;
    }
    if (!items) {
      return null;
    }
    // when using pagination
    if(items.results){
      return items.results.find( x => x.value === value);
    }
    return items.find( x => x.value === value);
  }


  render() {
    const { name, isMulti, search, disabled, error, touched, showError, defaultValue, value} = this.props;
    return (
      <div >
        <label htmlFor={name}>{this.props.label}</label>
        <Select
          id={name}
          options={this.getOptions()}
          isMulti={isMulti}
          name={name}
          search={search}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          isOptionDisabled={disabled}
          isClearable={true}
          theme={(theme) =>{return getTheme(theme)}}
          defaultValue={this.fromValueToObject(defaultValue)}
          value={this.fromValueToObject(value)}

        />
        {!!error && touched && showError && (
            <div className="invalid-feedback d-block">{error}</div>
          )}
      </div>
    );
  }
}


export default (SelectWrapper);
