import React, {Component} from 'react';


class RadioButton extends Component {

  onChanged(e) {
      return this.props.onChange( e.currentTarget.value );
  }

  render(){
    let className = "btn btn-radio btn"+(this.props.current === this.props.value ? "" : "-outline"  )+"-"+(this.props.color ? this.props.color : "primary" )+" "+this.props.className;
    return (
      <label className={className} htmlFor={this.props.name +'-'+ this.props.value }>
        <input type="radio"
                id={this.props.name +'-'+ this.props.value }
                 name={this.props.name}
                 value={this.props.value}
                 checked={this.props.current === this.props.value}
                 onChange={(e) => this.onChanged(e)}
                 className="d-none"
                 disabled={this.props.disabled}
                 />
        {this.props.text}
      </label>

    );
  }
}

export default RadioButton
