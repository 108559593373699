export default function reduer(state = {isLoading: false, authenticated: false, user: {profile:{}} }, action) {
    switch(action.type) {
        case "LOGIN":
            return { ...state, authenticated: true, token: action.payload.access, refresh: action.payload.refresh, isLoading: false};
        case "LOGOUT":
            return { ...state, authenticated: false, token: null, user: {profile: {}, center: null} };
        case "USER":
            return { ...state, user: action.payload, isLoading: false};

        // no default
    }
    return state;
}
