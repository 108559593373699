import update from 'immutability-helper';
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { bindActionCreators } from 'redux';
import {getItem, updateItem, updateItemTemp } from '../../../../../actions/Items/actions';

class RequestedMetaForm extends Component {

    handleChange(key, value) {
        var { current, updateItemTemp, index } = this.props;
        current = update(current, {
            'requested_meta': {
                [index]: {
                    [key]: {
                        $set: value
                    }
                }
            }
        });
        updateItemTemp(current);

    }

    render() {
        var {  meta, index } = this.props;
        return (
            <div className="border p-3 mb-3">
                <h6 className="text-muted">{'Item '+ meta.name }</h6>
                <FormGroup>
                    <Label >Name</Label>
                    <Input
                        type="text"
                        placeholder="Field name e.g. serial or prefix"
                        value={meta.name}
                        disabled={meta.item_meta_id > 0}
                        onChange={(e) => { this.handleChange("name", e.target.value) }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label >Placeholder</Label>
                    <Input
                        type="text"
                        placeholder="Example value e.g. P123456"
                        value={meta.placeholder}
                        onChange={(e) => { this.handleChange("placeholder", e.target.value) }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label >Side</Label>
                    <Input
                        type="text"
                        value={meta.side}
                        placeholder="Side from picture e.g. back, front or tag"
                        onChange={(e) => { this.handleChange("side", e.target.value) }}
                    />
                </FormGroup>
                <Row form>
                    <Col sm={8}>
                        <FormGroup>
                            <Label >Regex match</Label>
                            <Input
                                type="text"
                                disabled={true}
                                value={meta.regex_match }
                                onChange={(e) => { this.handleChange("regex_match", e.target.value) }}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={4}>
                        <FormGroup>
                            <Label >Regex cleaning</Label>
                            <Input
                                type="text"
                                disabled={true}
                                value={meta.regex_cleaning}
                                onChange={(e) => { this.handleChange("regex_cleaning", e.target.value) }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup check>
                    <Label check>
                        <Input  
                            type="checkbox" 
                            id={'check_uniqueness_' + index} 
                            defaultChecked={meta.check_uniqueness}
                            name="check_unqiueness"
                            onChange={(e) => {this.handleChange("check_uniqueness", !meta.check_uniqueness) }}
                             /> Check claim for uniqueness on this field
                            </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input 
                        type="checkbox" 
                        disabled={true} 
                        id={'check_warranty_period_' + index} 
                        defaultChecked={meta.check_this_field_for_warranty_period}
                        onChange={(e) => { this.handleChange("check_this_field_for_warranty_period", !meta.check_this_field_for_warranty_period ) }}
                         /> Use this field for warranty period check
                            </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input 
                        type="checkbox"  
                        id={'picture_only_' + index} 
                        defaultChecked={meta.picture_only}
                        onChange={(e) => { this.handleChange("picture_only", !meta.picture_only ) }}
                         /> Take a picture only, no warranty check or uniqueness check on this item is performed
                            </Label>
                </FormGroup>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        current: state.items.current,
        isLoading: state.items.isLoading,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateItem: bindActionCreators(updateItem, dispatch),
        getItem: bindActionCreators(getItem, dispatch),
        updateItemTemp: bindActionCreators(updateItemTemp, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestedMetaForm);