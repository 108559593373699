

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { listItems } from "../../../../actions/Items/actions";
import { Loading } from "../../../Helper/Loading";
import Items from "./components/Items";
import Filter from "./components/Filter";
import Form from "./components/Form";
export default function LelyItems(){
  const items = useSelector(state => state.items.items)
  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listItems({"status": null, "limit": 50}))
  }, [dispatch]);

  if (!items) {
    return <Loading></Loading>
  }

  if (params.item_id) {
    return <Form item_id={params.item_id} />
  }

  return (<div> <Filter />  <Items /></div>)
}
