import React, { useState } from "react";
import { useSelector } from "react-redux";
import { DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupButtonDropdown, Label } from "reactstrap";
export default function CenterSearch(props) {
    const { filter, setFilter, label } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const user = useSelector(state => state.auth.user)
  
    const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  
    return (<div>
        {label && <Label>Center</Label>}
      <InputGroup>
        <Input type="text" value={filter.search} onChange={(e) => setFilter({ ...filter, search: e.target.value })} placeholder="e.g. Paris"></Input>
        { ( user.is_lely_superuser) &&
          <InputGroupButtonDropdown addonType="append" isOpen={dropdownOpen} toggle={toggleDropDown}>
            <DropdownToggle caret>
              {filter.is_lna.label}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => setFilter({ ...filter, is_lna: { value: 1, label: 'LNA ONLY' } })}>LNA ONLY</DropdownItem>
              <DropdownItem onClick={() => setFilter({ ...filter, is_lna: { value: 0, label: 'NO LNA' } })}>NO LNA</DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => setFilter({ ...filter, is_lna: { value: '', label: 'ALL' } })}>ALL</DropdownItem>
            </DropdownMenu>
          </InputGroupButtonDropdown>
        }
      </InputGroup>
    </div>)
  
  }

  CenterSearch.defaultProps = {
      label: true
  }