import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
// import 'react-html5-camera-photo/build/css/index.css';
import { toast } from "react-toastify";
import Webcam from "react-webcam";
import { Button, Input, Progress } from "reactstrap";
import useWorkers from "../../../../workers/useWorkers";
import { postClaim } from "../actions";

const WATING_SECONDS = 10;

// Adjust the path to your worker file
// import workerFunction from "../../../../workers/ocr.worker";

const regexConfigs = [
    { item_number: "9.1185.0467.0", item_id: 1, item_meta_id: 1, field: "serial", side: "Tag", regex: /[^a-zA-Z\s\d]?([A-Z]{1,2}\d{7})[^a-zA-Z\s\d]?/, cleaning: "$1" },
    { item_number: "9.1189.0295.0", item_id: 2, item_meta_id: 2, field: "serial", side: "Tag", regex: /[^a-zA-Z\s\d]?([A-Z]{1,2}\d{7})[^a-zA-Z\s\d]?/, cleaning: "$1" },
    { item_number: "9.1185.0465.0", item_id: 3, item_meta_id: 3, field: "serial", side: "Tag", regex: /([A-Z]{1,2}\d{7})/, cleaning: "$1" },
    { item_number: "9.1185.0454.2", item_id: 4, item_meta_id: 4, field: "serial", side: "Tag", regex: /[^a-zA-Z\s\d]?([A-Z]{1,2}\d{7})[^a-zA-Z\s\d]?/, cleaning: "$1" },
    { item_number: "9.1185.0453.0", item_id: 5, item_meta_id: 5, field: "serial", side: "Tag", regex: /([A-Z]{1,2}\d{7})/, cleaning: "$1" },
    { item_number: "9.1185.0453.1", item_id: 10, item_meta_id: 6, field: "serial", side: "Tag", regex: /([A-Z]{1,2}\d{7})/, cleaning: "$1" },
    { item_number: "9.1138.0467.0", item_id: 7, item_meta_id: 7, field: "prefix", side: "Front", regex: /([^a-zA-Z\s\d]?)(([A-Z][OND|0-9][0O][2?])|([A-Z][OND|0-9]\d{2})|((0[1-9]|1[0-2])-(2[0-1])\d{2}))(:?43)?([^a-zA-Z\s\d]?)/, cleaning: "$2" },
    { item_number: "9.1138.0467.0", item_id: 7, item_meta_id: 9, field: "serial", side: "Back", regex: /[^a-zA-Z\s\d]?(P\d{7})[^a-zA-Z\s\d]?/, cleaning: "$1" },
]

// Lely formats string as 'key1=value1; key2=value2; key3=value3'
function convertStringToJSONObject(inputString) {
    const keyValuePairs = inputString.split(';');
    const jsonObject = {};

    for (const pair of keyValuePairs) {
        const [key, value] = pair.trim().replace(/(\$|'| )/g, '').split('=');
        if (key && value) {
            jsonObject[key] = value;
        }
    }

    return jsonObject;
}



const getRegex = (user, side, meta, item_number) => {
    let regex = []

    if (side === "SOMINT-label") {
        regex = [
            { field: "item_number", regex: /(\d\.\d{4}\.\d{4}[.\w]\d)/, cleaning: "$1" },
            { field: "a_number", regex: /(\d{13})/, cleaning: "$1" },
        ]
        if (!user.center.is_lna) {
            regex.push({ field: "warranty_request", regex: /(WR\d{5})/, cleaning: "$1" })
        }
        return regex;
    }

    if (side === "SMART-label") {
        return [
            { field: "item_number", regex: /(\d\.\d{4}\.\d{4}[.\w]\d)/, cleaning: "$1" },
            { field: "warranty_request", regex: /(C+\d{6,7})/, cleaning: "$1" },
            { field: "a_number", regex: /(N|R)+(\d{6,7}-\d{3})/, cleaning: "$1$2" }
        ];
    }

    // TODO: add compass configs
    if (side === "COMPASS_QR-label") {
        return [
            { field: "item_number" },
            { field: "warranty_request" },
            { field: "a_number" },
            { field: "work_date" }
        ]
    }
    if (side === "COMPASS_BARCODE-label") {
        return [
            { field: "item_number" },
            { field: "warranty_request" },
            { field: "a_number" },
        ]
    }
    //meta
    if (meta.use_OCR) {
        const config = regexConfigs.find(i => i.item_number === item_number && i.field === meta.name)
        if (config) {
            config.result = meta.scanned
            return [config]
        }
        return regexConfigs
        // regex = regexConfigs
        // regex = [{ field: meta.name, regex: /[^a-zA-Z\s\d]?([A-Z]{1,2}\d{7})[^a-zA-Z\s\d]?/, cleaning: "$1" }]
    }

    return []

}

const QR = "QR"
const OCR = "OCR"
export const MODE = { QR, OCR }

export default function CameraUnitV2({ side, meta, mode = MODE.QR }) {
    const post = useSelector(state => state.addclaim.post);
    const labels = useSelector(state => state.addclaim.labels);
    const user = useSelector(state => state.auth.user);
    const webcamRef = React.useRef(null);
    const dispatch = useDispatch();
    const [focusmode, setFocusMode] = useState({ browser: [], capabilities: [], constraint: "", user_agent: "" })
    const [zoomConstraints, setZoomConstraints] = useState(false)
    const [zoomSliderValue, setZoomSliderValue] = useState(false)
    const [regex, setRegexState] = useState([])
    const regexLengthRef = useRef(0)
    const jobQueueLengthRef = useRef(0); // Ref to store job queue length
    const [clickCount, setClickCount] = useState(0);

    const isToggled = clickCount % 4 === 3;

    // const zoom = false
    const zoom_timer = useRef(null);
    const ocr_timer = useRef(null);
    const countdown_timer = useRef(null);
    const [camera_results, setCameraResults] = useState(["Waiting for video..."]);
    const isLabel = side === "SOMINT-label" || side === "SMART-label" || side === "COMPASS_QR-label" || side === "COMPASS_BARCODE-label";
    const hasImage = post.images && post.images[side];
    const imageNeeded = isLabel || meta?.use_OCR;

    const dispatchOneResult = useCallback((field, text, isLabel) => {

        if (isLabel) {
            post[field] = text;
            const isLabelComplete = post.a_number && post.warranty_request && post.item_number
            dispatch(postClaim({ ...post, step: isLabelComplete ? "validate_label" : post.step }));
            // dispatch(postClaim({ ...post, [field]: text }));
        }
        if (meta?.use_OCR) {
            dispatch(postClaim({
                ...post,
                item: {
                    ...post.item,
                    requested_meta: post.item.requested_meta.map(i => i.item_meta_id === meta.item_meta_id ? ({ ...i, is_scanned: true, scanned: text }) : i)
                }
            }));
        }
    }, [post, dispatch, meta]);

    const dispatchResults = useCallback((data) => {
        const newData = { ...post, ...data }
        const isLabelComplete = newData.a_number && newData.warranty_request && newData.item_number
        dispatch(postClaim({ ...newData, step: isLabelComplete ? "validate_label" : post.step }));
    }, [post, dispatch]);

    //  * either QR or OCR
    const handleWorkerMessage = useCallback((event) => {
        const text = event?.text
        if (text) {
            setCameraResults(prev => [...prev.slice(Math.max(prev.length - 2, 0)), text])
            if (event.mode === MODE.QR) {
                const parsedData = convertStringToJSONObject(text);
                const updatedData = {
                    warranty_request: parsedData.RequestClaimID,
                    item_number: parsedData.ItemID,
                    a_number: parsedData.ClaimLineID,
                    work_date: parsedData.WorkDate,
                };
                dispatchResults(updatedData);
                setRegexState(prev => prev.map(i => ({ ...i, result: updatedData?.[i.field] || i.result })))
                return;
            }
            const filteredText = text.toUpperCase().replace("|", "J").replace("{", "J").replace("}", "J").replace("°", "P").replace("?", "2").replace("$", "J").replace("@", "Q")
            setRegexState(prev => prev.map(i => {
                if (i.result) {
                    return i
                }
                let result = filteredText.match(i.regex)
                if (result) {
                    result = result[0].replace(i.regex, i.cleaning)
                    if (i.field) {
                        dispatchOneResult(i.field, result, isLabel)
                    }
                }
                return { ...i, result }
            }
            ))
        }

    }, [dispatchOneResult, dispatchResults, isLabel]);

    const { addJob, jobQueue } = useWorkers(handleWorkerMessage, jobQueueLengthRef, mode);

    // * Screenshot interval
    useEffect(() => {
        if (ocr_timer.current) {
            clearInterval(ocr_timer.current)
        }

        ocr_timer.current = setInterval(() => {
            if (!webcamRef.current) {
                // *no camera yet
                return
            }

            if (jobQueueLengthRef.current > 3) {
                // * queue already full
                console.log("queue full")
                return
            }

            if (regexLengthRef.current === 0) {
                // * no regex needed
                return
            }
            const screenshot = webcamRef.current.getScreenshot();
            if (screenshot) {
                addJob({ screenshot, mode });
            }
        }, 250);


        return () => {
            clearInterval(ocr_timer.current)
        }

    }, [mode, addJob]);



    const [videoConstraints, setVideoConstraints] = useState({
        facingMode: "environment",
        focusMode: "continuous",
        width: 640,
        height: 480
    });

    // State for countdown and progress
    const [countdown, setCountdown] = useState(WATING_SECONDS * 2); // 8 seconds countdown

    // Start countdown
    useEffect(() => {
        // Reset countdown and progress when it reaches 0
        countdown_timer.current = setInterval(() => {
                setCountdown(prev => (hasImage || !imageNeeded) ? WATING_SECONDS * 2 : Math.max(prev - 1, 0));
        }, 500);
        return () => clearInterval(countdown_timer.current);

    }, [hasImage, imageNeeded]);

    const handleClick = () => {
        setClickCount(prev => prev + 1);

        if (clickCount === 3) {
            setClickCount(0); // Reset the click count after the third click
        }
    };

    // * init regex object
    useEffect(() => {
        const _regex = getRegex(user, side, meta, post.item_number)
        setRegexState(_regex)
        regexLengthRef.current = _regex.length
    }, [side, user, meta, post.item_number])


    const setZoomSliderValueConstraint = (value) => {
        setZoomSliderValue(value)
        zoom_timer.current = setTimeout(() => setZoomConstraints((prev) => ({ ...prev, value })), 250)
    }

    const capture = () => {
        if (countdown > 1 && imageNeeded && regex.filter(i => !i.result).length > 0) {
            toast.info("Please point your camera at the label and try for a minimum of 8 seconds.", {
                autoClose: 2500,
            });
            // Add 3 seconds to the countdown
            setCountdown(_ => (3 * 2) + 1);
            return;
        }
        const dataUri = webcamRef.current.getScreenshot();
        const images = !post.images ? {} : post.images;
        images[side] = { base64: dataUri, processed: false, focusmode }
        dispatch(postClaim({ ...post, images }));
        dispatch(postClaim({ ...post, images }, true));

    };

    const removeImage = () => {
        const _post = { ...post, images: { ...post.images, [side]: false } }
        dispatch(postClaim(_post));
        setCountdown(_ => (WATING_SECONDS * 2) + 1);
    }

    const onCameraError = (error) => {
        console.error("onCameraError", error);
        toast.error("Failed to start camera: (" + error.name + ":" + error.message + "). Please stop any application that is using your camera.", {
            toastId: "create-location",
            autoClose: 3500,
        });
    }

    const logCapabilities = (media) => {
        console.log("THE MEDIA", media)
        const SupportedConstraints = navigator.mediaDevices.getSupportedConstraints();
        console.log("getSupportedConstraints by browser", SupportedConstraints)
        const focusModeStatus = { browser: SupportedConstraints.focusMode, capabilities: [], constraint: "", user_agent: navigator.userAgent }
        let capabilities = false
        if (media.getVideoTracks) {
            const tracks = media.getVideoTracks()
            console.log("tracks", tracks);
            if (tracks && tracks.length > 0) {
                const track = tracks[0]
                if (track.getCapabilities) {
                    capabilities = track.getCapabilities()
                    console.log("capabilties", capabilities)
                    focusModeStatus.capabilities = capabilities.focusMode

                }
                if (track.getSettings) {
                    const settings = track.getSettings()
                    console.log("settings", settings)
                    focusModeStatus.constraint = settings.focusMode || ""
                    console.log("enable zoom?", settings && capabilities && "zoom" in settings && "zoom" in capabilities)

                    if (settings && capabilities && "zoom" in settings && "zoom" in capabilities) {
                        if (zoomConstraints === false) {
                            setZoomConstraints({ ...capabilities.zoom, value: capabilities.zoom.min })
                            setZoomSliderValue(capabilities.zoom.min)
                        }
                    }
                }
                setFocusMode(focusModeStatus)
                console.log(focusModeStatus)
            }
        }
    }

    if (post.images && post.images[side]) {
        return (
            <div className="position-relative">

                <div className="ocr-camera__toggle">
                    <div className="ocr-camera__toggle">
                        <Button color="secondary" onClick={() => removeImage()} size="sm"><FontAwesomeIcon icon={["far", "pencil"]} /></Button>
                    </div>
                </div>
                <img className="d-block h-100 w-100" alt={`Item $side`} src={post.images[side].url ? post.images[side].url : post.images[side].base64} onClick={() => removeImage()} />
            </div>)
    }
    const item_number = post.item_number ? "item-" + post.item_number.replace(/\./g, "-") : "label";
    return (
        <div>

            <div className={"position-relative bg-light ocr-camera -" + side + " -" + item_number} >
                <div className="camera">
                    <Webcam
                        onUserMediaError={onCameraError}
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/png"
                        forceScreenshotSourceSize={true}
                        onUserMedia={logCapabilities}
                        imageSmoothing={false}
                        videoConstraints={zoomConstraints ? { ...videoConstraints, advanced: [{ "zoom": zoomConstraints.value }] } : videoConstraints}

                    />
                </div>
                <div className="ocr-camera__overlay" onClick={capture}></div>
                <div className="ocr-camera__toggle">
                    <Button
                        color="secondary"
                        onClick={() => setVideoConstraints((prev) => ({ ...prev, facingMode: videoConstraints.facingMode === "environment" ? "user" : "environment" }))}
                        size="sm"><FontAwesomeIcon icon={["far", "redo"]} /> <FontAwesomeIcon className="" icon={["far", "video"]} /></Button>
                </div>
            </div>
            { (meta.use_OCR || isLabel) && <div className="pt-1">
                <Progress value={countdown * (100 / (WATING_SECONDS * 2))} animated />
            </div>}
            {zoomConstraints &&
                <div className="py-1">
                    {/* {zoomConstraints.min} -{zoomConstraints.max} <br></br>
          step {(zoomConstraints.max - zoomConstraints.min) / 5}  <br></br>
          slidervalue = {zoomSliderValue} <br></br>
          video value = {zoomConstraints.value} */}

                    <Input
                        name="range"
                        type="range"
                        min={zoomConstraints.min}
                        max={zoomConstraints.max}
                        step={(zoomConstraints.max - zoomConstraints.min) / 5}
                        value={zoomSliderValue}
                        onChange={e => setZoomSliderValueConstraint(e.target.value)}
                    />
                </div>
            }
            {isToggled && <div> Jobs: {jobQueue.length}</div>}
            {regex.map((i, index) => <div key={index} className="pt-2">
                {isToggled && <i>{String(i.regex)}<br></br></i>}
                <b><span onClick={handleClick}>{labels?.[i.field]?.label || i.field}</span> :</b>
                {i.result && <span className="text-success"><b>{i.result} Take a picture and continue!</b></span>}
                {!i.result && countdown > 0 && <span className="text-warning"> Point and scan!</span>}
                {!i.result && countdown <= 0 && <span className="text-danger"> Keep trying or click image to continue.</span>}
            </div>)}
            <pre>
                {isToggled && camera_results.map((i, index) => <div key={index}>{i}</div>)}

            </pre>

        </div>
    );

}
