import update from 'immutability-helper';
import { toast } from 'react-toastify';
import { BULK_UPDATE, CREATE, DELETE, GET, LIST, SIGNALS, UPDATE } from './actionsTypes';
import { defaultFilter, defaultSignal } from "./constants";
const signals = {
    items: false,
    thread: false,
    related: false,
    isLoading: false,
    relatedIsLoading: false,
    isUpdating: false,
    current: defaultSignal,
    refresh_date: false,
    filter: defaultFilter,

    currentView: 'signal'
};
export default function reducer (state = signals, action) {
    var indexOfItem = -1;
    switch (action.type) {
        case LIST.FILTER:
            return { ...state, filter: action.payload }

        case LIST.LOADING:
            return { ...state, isLoading: true, filter: action.payload.request.filter_object };
        case GET.LOADING:
            return { ...state, isLoading: true };

        case CREATE.LOADING:
            toast.info('Signaal opslaan...', {
                toastId: 'create-signal',
                autoClose: 1500,
            });
            return { ...state, isUpdating: true };

        case BULK_UPDATE.LOADING:
            toast.info('Updating claims...', {
                toastId: 'update-signal-bulk',
                autoClose: 25000
            });

            return { ...state, isUpdating: true };

        case UPDATE.LOADING:
            toast.info('Claim opslaan...', {
                toastId: 'update-signal-' + action.payload.request.data.signal_id,
                autoClose: 25000
            });

            return { ...state, isUpdating: true };

        case DELETE.LOADING:
            toast.warning('Signaal verwijderen...', {
                toastId: 'delete-signals',
                autoClose: 1500,
            });
            indexOfItem = state.items.results.findIndex(x => x.signal_id === action.payload.request.signal_id);
            return update(state, {
                isUpdating: { $set: true },
                items: {
                    results: { $unset: [indexOfItem] },
                }
            });


        case LIST.ERROR:
        case GET.ERROR:
        case CREATE.ERROR:
        case UPDATE.ERROR:
        case DELETE.ERROR:
            return { ...state, isLoading: false, isUpdating: false };


        case LIST.SUCCESS:
            return { ...state, isLoading: false, items: action.payload.data };


        case SIGNALS.SELECT:
            return { ...state, isLoading: false, current: action.payload.data }
        case GET.SUCCESS:
            // state = update_signal_state(state, action, action.meta.previousAction.payload.close_after_save);

            return { ...state, isLoading: false };


        case CREATE.SUCCESS:
            toast.update('create-signal', { render: "Signaal opgeslagen!", type: toast.TYPE.SUCCESS, autoClose: 3500 });
            return { ...state, isLoading: false, isUpdating: false, current: action.payload.data };
        // case UPDATE.STATE:
        //     return update_signal_state(state, action, false);

        case UPDATE.SUCCESS:
            toast.update('update-signal-' + action.payload.data.signal_id, { render: "Claim saved!", type: toast.TYPE.SUCCESS, autoClose: 1200 });
            return { ...state, isLoading: false, isUpdating: false }

        case BULK_UPDATE.SUCCESS:
            toast.update('update-signal-bulk', { render: "Claims updated!", type: toast.TYPE.SUCCESS, autoClose: 1200 });
            return { ...state, isLoading: false, isUpdating: false }


        case DELETE.SUCCESS:
            toast.update('delete-signals', { render: "Signaal verwijderd", type: toast.TYPE.WARNING, autoClose: 3500 });
            return { ...state, isLoading: false, isUpdating: false, current: action.payload.data };

        default:
            return state;
    }
}

