import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { Alert, Col, Container, Row } from "reactstrap";
import apiClient from "../constants/apiClient";
import { AuthUrls } from "../constants/urls";
import Footer from "./Footer";
import Header from "./Header";
import Redirecting from "./Redirecting";
import AddClaim from "./scenes/AddClaim";
import Admin from "./scenes/Admin";
import AddWRNumber from "./scenes/Admin/AddWRNumber";
import ExportCsv from "./scenes/Admin/ExportCsv";
import LelyCenters from "./scenes/Admin/LelyCenters";
import LelyItems from "./scenes/Admin/LelyItems";
import Login from "./scenes/Auth/Login";
import { authLogin, getUser } from "./scenes/Auth/actions";
// import NoMatch from "./NoMatch";
import Inbox from "./scenes/Inbox";


const routes = (navigate) => {
  return [
    {
      path: "/oauth2/:sub",
      element: <Redirecting destination="the authentication server" />,
    },
    {
      path: "/",
      element: <RequireAuth> <Inbox /> </RequireAuth>,
    },
    {
      path: "/add-claim",
      element: <RequireAuth fullscreen={true}> <AddClaim navigate={navigate} /> </RequireAuth>,
    },
    {
      path: "/login",
      element: <Login />,
      children: [
        { path: ":action", element: <Login /> },
        { path: ":action/:access", element: <Login /> }
      ]
    },
    {
      path: "/_admin",
      element: <RequireAuth extra_authorisation_callback={(user) => user.is_lely_superuser || user.is_lna_superuser} ><Admin /></RequireAuth>,
      children: [
        { path: "centers", element: <RequireAuth fullscreen={true} extra_authorisation_callback={(user) => user.is_lely_superuser || user.is_lna_superuser} ><LelyCenters /></RequireAuth> },
        { path: "centers/:lely_center_id", element: <RequireAuth fullscreen={true} extra_authorisation_callback={(user) => user.is_lely_superuser} ><LelyCenters /></RequireAuth> },
        { path: "items", element: <RequireAuth fullscreen={true} extra_authorisation_callback={(user) => user.is_lely_superuser} ><LelyItems /></RequireAuth> },
        { path: "items/:item_id", element: <RequireAuth fullscreen={true} extra_authorisation_callback={(user) => user.is_lely_superuser} ><LelyItems /></RequireAuth> },
        { path: "add-wr-number", element: <RequireAuth fullscreen={true} extra_authorisation_callback={(user) => user.is_lely_superuser || user.is_lna_superuser} ><AddWRNumber /></RequireAuth> },
        { path: "export-csv", element: <RequireAuth fullscreen={true} extra_authorisation_callback={(user) => user.is_lely_superuser || user.is_lna_superuser} ><ExportCsv /></RequireAuth> },
      ],
    },
  ]
}


export default function MainContent() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const routing = useRoutes(routes(navigate));
  const location = useLocation();
  const is_login_page = location.pathname.substring(0, 5) === "/login"
  useEffect(() => {
    const refresh_token = localStorage.getItem("refresh_token");
    if (!refresh_token || refresh_token === "undefined") {
      if (!is_login_page) {
        navigate("/login");
      }
      return;
    }

    apiClient.post(AuthUrls.TOKEN_REFRESH, { "refresh": refresh_token }).then((response) => {
      // If request is good...
      // Update state to indicate user is authenticated
      const token = response.data.access;
      localStorage.setItem("token", token);
      dispatch(getUser());
      dispatch(authLogin(token));
    }).catch((error) => console.log(error));

  }, [dispatch, navigate, location.pathname, is_login_page]);

  return (
    <div>
      {routing}
    </div>
  );
}


function RequireAuth(props) {
  const { extra_authorisation_callback, children, fullscreen } = props;
  const { user, isLoading } = useSelector((state) => state.auth);

  if (isLoading && typeof user.center === "undefined") {
    return (<div className="text-center py-5 opacity-80">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>);
  }

  if (user?.center === null) {
    return (

      <Layout fullscreen={fullscreen}>
        <Container className="py-3">
          <Row>
            <Col sm="12">
              <Alert color="warning">
                Your account cannot be matched to a Lely Center. Please contact support.
              </Alert>
            </Col>
          </Row>
        </Container>
      </Layout>
    )

  }

  if (user?.center?.status === "BLOCKED") {
    return (
      <Layout fullscreen={fullscreen}>
        <Container className="py-3">
          <Row>
            <Col sm="12">
              <Alert color="warning">
                Your Lely Center is blocked from this application. Please contact support.
              </Alert>
            </Col>
          </Row>
        </Container>
      </Layout>
    )

  }


  if (!extra_authorisation_callback(user)) {
    return (

      <Layout fullscreen={fullscreen}>
        <Container className="py-3">
          <Row>
            <Col sm="12">
              <Alert color="warning">
                You cannot access this section from the Phototool. Please contact support.
              </Alert>
            </Col>
          </Row>
        </Container>
      </Layout>

    )
  }

  if (fullscreen) {
    return children
  }
  return <Layout fullscreen={fullscreen}>{children}</Layout>

}

RequireAuth.defaultProps = {
  extra_authorisation_callback: (user) => true,
  fullscreen: false
}


function Layout({ children, fullscreen }) {
  if (fullscreen) {
    return children
  }
  return <div>
    <Header />
    <div>{children}</div>
    <Footer />
  </div>
}
