import { useEffect, useRef, useState } from 'react';
import { createWorker } from 'tesseract.js';
// eslint-disable-next-line import/no-webpack-loader-syntax
import QRworker from 'workerize-loader!./qr.worker.js';
import { MODE } from '../components/scenes/AddClaim/components/CameraV2';

const useWorkers = (onMessage, jobQueueLengthRef, mode = MODE.OCR, numWorkers = 4) => {
    const workersRef = useRef([]);
    const [jobQueue, setJobQueue] = useState([]);
    const timerRef = useRef(null);
    const jobIdRef = useRef(0); // Ref to keep track of unique job IDs
    const jobTimeout = 20000; // 20 seconds in milliseconds

    useEffect(() => {
        // Initialize workers if not already initialized
        if (workersRef.current.length === 0) {
            if (mode === MODE.QR) {
                console.log("Initializing QR workers...");
                workersRef.current = Array.from({ length: numWorkers }, () => QRworker());
            } else {
                const initializeWorkers = async () => {
                    console.log("Initializing OCR workers...");
                    const workersPromises = Array.from({ length: numWorkers }, async () => await createWorker('eng'));
                    workersRef.current = await Promise.all(workersPromises);
                }
                initializeWorkers();
            }
        }

        return () => {
            // Cleanup workers on component unmount
            console.log("Terminating workers...");
            workersRef.current.forEach(workerInstance => {
                if (workerInstance) {
                    workerInstance.terminate();
                }
            });
            workersRef.current = []; // Clear the reference to avoid memory leaks
        };
    }, [numWorkers, mode]);

    const handleNextJob = async () => {
        setJobQueue(prevQueue => {
            if (prevQueue.length === 0) return prevQueue;

            const now = Date.now();
            const nextJob = prevQueue[0];

            // Check if the job has timed out
            if (now - nextJob.timestamp > jobTimeout) {
                console.log(`Job ${nextJob.id} has timed out and will be removed.`);
                return prevQueue.slice(1); // Remove the timed-out job from the queue
            }

            const workerIndex = nextJob.id % numWorkers;

            // console.log("Sending job", nextJob.id ,"to worker", workerIndex);

            if (workersRef.current[workerIndex]) {
                const workerInstance = workersRef.current[workerIndex];

                // Determine the job type and call the appropriate worker function
                if (nextJob.mode === MODE.OCR) {
                    workerInstance.recognize(nextJob.screenshot).then(({ data: { text } }) => {
                        onMessage({ status: 'done', text, mode: nextJob.mode });
                        removeJobById(nextJob.id);
                    })
                        .catch(error => {
                            onMessage({ status: 'error', error: error.message });
                            removeJobById(nextJob.id);
                        });
                } else if (nextJob.mode === MODE.QR) {
                    workerInstance.recognizeQR(nextJob.screenshot)
                        .then(text => {
                            onMessage({ status: 'done', text, mode: nextJob.mode });
                            removeJobById(nextJob.id);
                        })
                        .catch(error => {
                            onMessage({ status: 'error', error: error.message });
                            removeJobById(nextJob.id);
                        });
                }
            }

            return prevQueue; // Do not remove the job yet
        });
    };

    const removeJobById = (jobId) => {
        setJobQueue(prevQueue => {
            const newQueue = prevQueue.filter(job => job.id !== jobId);
            jobQueueLengthRef.current = newQueue.length;
            return newQueue
        });
    };

    useEffect(() => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
        timerRef.current = setInterval(() => {
            if (jobQueueLengthRef.current > 0 && workersRef.current.length > 0) {
                handleNextJob();
            }
        }, 250);

        return () => {
            clearInterval(timerRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addJob = (job) => {
        setJobQueue(prevQueue => {
            const jobWithId = { ...job, id: jobIdRef.current++, timestamp: Date.now() }; // Add unique ID and timestamp to the job
            jobQueueLengthRef.current = prevQueue.length + 1;
            return [...prevQueue, jobWithId];
        });
    };

    return { addJob, jobQueue, jobQueueLengthRef };
};

export default useWorkers;
