export const ROOT_URL = process.env.REACT_APP_API_ENDPOINT || window.location.origin + '/';
export const AuthUrls = {
  LOGIN: `${ROOT_URL}api/auth/jwt/create/`,
  GET_FROM_SESSION: `${ROOT_URL}api/auth/jwt/get/`,
  TOKEN_REFRESH: `${ROOT_URL}api/auth/jwt/refresh/`,
  VERIFY_TOKEN: `${ROOT_URL}api/auth/jwt/verify/`,
  SIGNUP: `${ROOT_URL}api/auth/users/create`,
  // CHANGE_PASSWORD: `${ROOT_URL}api/auth/users/password/change/`,
  RESET_PASSWORD: `${ROOT_URL}api/auth/users/reset_password/`,
  RESET_PASSWORD_CONFIRM: `${ROOT_URL}api/auth/users/reset_password_confirm/`,
  USER_ACTIVATION: `${ROOT_URL}api/auth/users/registration/verify-email/`,
  USER: `${ROOT_URL}api/auth/users/me/`
};

export const ApiUrls = {
  orders: `${ROOT_URL}api/orders/`,
  lossProcesses: `${ROOT_URL}api/processes/`,
  subUsers: `${ROOT_URL}api/subusers/`,
  events: `${ROOT_URL}api/events/`,
  suppliers: `${ROOT_URL}api/suppliers/`,
  species: `${ROOT_URL}api/species/`,
  ps_upload: `${ROOT_URL}api/ps-upload`
}
