
export const defaultSignal = {
    signal_id: null,
}


export const signal_type_options = [
    {
        label: 'NAO',
        value: 'nao'
    },
    {
        label: 'SL',
        value: 'sl'
    },
];

export const signal_status_options = [
    {
        label: 'NIEUW',
        value: 'NIEUW'
    },
    {
        label: 'OPEN',
        value: 'OPEN'
    },
    {
        label: 'WACHT',
        value: 'WACHT'
    },
    {
        label: 'AFGEROND',
        value: 'AFGEROND'
    },
    {
        label: 'SUCCES',
        value: 'SUCCES'
    },
    {
        label: 'VERVALLEN',
        value: 'VERVALLEN'
    },

]

export const defaultFilter = { user_id: '', type: '', status: '', warranty_request: '', a_number: '', item_number: '', created: '', offset: 0, limit: 25, is_lna: { value: '', label: 'ALL' } }
