import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Label, Input, FormGroup, Container } from "reactstrap";
import { bindActionCreators } from 'redux';
import { createItem, updateItem, getItem, updateItemTemp } from '../../../../../actions/Items/actions';
import update from 'immutability-helper';
import ButtonSpinner from "../../../../Forms/ButtonSpinner";
import RequestedMeta from './RequestedMetaForm';
import { Link } from "react-router-dom";


class ItemForm extends Component {

    componentDidMount() {
        var { getItem, updateItemTemp, item_id } = this.props;
        if (item_id > 0) {
            getItem(item_id);
        }else{
            updateItemTemp({
                item_id: '',
                item_number: '',
                name: '',
                requested_meta: []
            })
        } 
    }


    handleChange(key, value) {
        var { current, updateItem, updateItemTemp } = this.props;
        current = update(current, { [key]: { $set: value } });

        updateItemTemp(current);
        if(!current.item_number && !current.name ){
            return
        }
        if(!current.item_id){
            createItem(current)
            return
        }
        
        updateItem(current);
    }

    addMeta() {
        var { current, updateItemTemp } = this.props;
        current = update(current, {
            'requested_meta': {
                $push: [{
                    name: '',
                    placeholder: '',
                    check_uniqueness: true,
                    check_this_field_for_warranty_period: false,
                    picture_only: false
                }]
            }
        });
        updateItemTemp(current);
    }
    submit() {
        var { current, updateItem, createItem } = this.props;
        if(current.item_id > 0){
            updateItem(current, true);
        }else{
            createItem(current);
        }
    }
    render() {
        var { isUpdating, current, item_id } = this.props;
        return (
            <Container className="py-3">
                <Row className="no-gutters justify-content-center">
                    <Col sm="12" md="9" lg="8">
                        <div className="border p-3 mb-3">
                            <h5 className="text-muted">{current.item_id > 0 ? 'Item ' + current.item_id : 'New item'}</h5>
                            <FormGroup>
                                <Label >Name</Label>
                                <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    placeholder="valve 4mm"
                                    value={current.name}
                                    onChange={(e) => { this.handleChange("name", e.target.value) }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label >Item number</Label>
                                <Input
                                    id="item_number"
                                    name="item_number"
                                    placeholder="9.1138.0821.4"
                                    type="text"
                                    disabled={item_id > 0}
                                    value={current.item_number}
                                    onChange={(e) => { this.handleChange("item_number", e.target.value) }}
                                />
                            </FormGroup>
                        </div>
                        {current.item_id > 0 &&
                            <div>
                                {current.requested_meta.map((meta, index) =>
                                    <RequestedMeta key={'requested_meta_' + index} index={index} meta={meta} />
                                )}
                                <div className="border border-dashed p-3 mb-3">
                                    <Button color="secondary" className="btn-block" onClick={() => this.addMeta()} >Add meta</Button>
                                </div>
                            </div>
                        }
                        <div className="py-3">
                            <Row>
                                <Col>
                                    <Link to="/_admin/items/" className="btn btn-secondary btn-block">Cancel</Link>
                                </Col>
                                <Col>
                                    <ButtonSpinner color="primary" loading={isUpdating} className="btn-block" onClick={() => this.submit()} >Save</ButtonSpinner>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        current: state.items.current,
        isUpdating: state.items.isUpdating,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        createItem: bindActionCreators(createItem, dispatch),
        updateItem: bindActionCreators(updateItem, dispatch),
        updateItemTemp: bindActionCreators(updateItemTemp, dispatch),
        getItem: bindActionCreators(getItem, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemForm);
