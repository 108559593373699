import apiClient from "../../../constants/apiClient";
import { toast } from 'react-toastify';
import { ROOT_URL } from "../../../constants/urls";

function setUsers(data) {
  return {
    type: "LOAD_USERS",
    payload: data
  };
}

export function getUsers() {

  return function(dispatch) {
    apiClient.get(`${ROOT_URL}api/auth/users/`).then(response => {
      dispatch(setUsers(response.data))
    });

  };
}

export function updateItem(item){
  return function(dispatch){
      toast.info('Updating signal...', {
        toastId: 'update-signal-' + item.signal_id,
        autoClose: 1500,
    });
     return apiClient.patch(`${ROOT_URL}api/signals/${item.signal_id}/`, item).then((response) => {
        toast.update('update-signal-' +response.data.signal_id, { render: "Signal updated!", type: toast.TYPE.SUCCESS, autoClose: 3500 });
    }).catch((error) => console.log('error nog logged in', error));;
  }
}
