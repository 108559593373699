import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import axiosMiddleware from 'redux-axios-middleware';
import apiClient from '../constants/apiClient'
import { ROOT_URL } from '../constants/urls';
function configureStore(initialState) {
    let createStoreWithMiddleware;
    apiClient.defaults.baseURL = ROOT_URL+'api'; 
    const logger = createLogger();
    const middleware = applyMiddleware(thunk, axiosMiddleware(apiClient), logger);
    

    createStoreWithMiddleware = compose(
        middleware
    );

    return createStoreWithMiddleware(createStore)(rootReducer, initialState);
}

let store = configureStore();

export default store;