import React from "react";
import { useSelector } from "react-redux";
import { Loading } from "../../../Helper/Loading";
import PictureStep from "./PictureStep";


export default function LabelPicture() {
  const user = useSelector(state => state.auth.user);


  if (typeof user.center === "undefined" || typeof user.center.system === "undefined") {
    return (<Loading></Loading>);
  }

  const side = user.center.system + "-label";


  return (
    <PictureStep
      title="Label Picture"
      side={side}
      nextStep="validate_label"
      prevStep="home"
      />
  );
}

