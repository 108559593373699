import update from 'immutability-helper';
import { toast } from 'react-toastify';
import { CREATE, DELETE, GET, LIST, UPDATE } from './actionsTypes';
const items = {
    items: false,
    isLoading: false,
    isUpdating: false,
    current: false,
    filter: { item_name: '', item_number: '', search: '', limit: 50, ordering: '-created' },
};
export default function reducer (state = items, action) {
    var indexOfItem = -1;
    switch (action.type) {
        case LIST.FILTER:
            return {...state, filter: action.payload}
        case LIST.LOADING:
        case GET.LOADING:
            return { ...state, isLoading: true };

        case CREATE.LOADING:
            toast.info('Creating item...', {
                toastId: 'create-item',
                autoClose: 1500,
            });
            return { ...state, isUpdating: true };

        case UPDATE.LOADING:
            toast.info('Updating ' + action.payload.request.data.name + '...', {
                toastId: 'update-item-' + action.payload.request.data.item_id,
                autoClose: 1500,
            });
            return { ...state, isUpdating: true };

        case DELETE.LOADING:
            toast.warning('Deleting item...', {
                toastId: 'delete-item',
                autoClose: 1500,
            });
            return update(state, {
                isUpdating: { $set: true },
            });


        case LIST.ERROR:
        case GET.ERROR:
        case CREATE.ERROR:
        case UPDATE.ERROR:
        case DELETE.ERROR:
            return { ...state, isLoading: false, isUpdating: false };

        case LIST.SUCCESS:
            return { ...state, isLoading: false, items: action.payload.data };

        case GET.SUCCESS:
            return { ...state, isLoading: false, current: action.payload.data };


        case CREATE.SUCCESS:
            toast.update('create-item', { render: action.payload.data.name + " created!", type: toast.TYPE.SUCCESS, autoClose: 3500 });
            return { ...state, isLoading: false, isUpdating: false, current: action.payload.data };

        case UPDATE.SUCCESS:
            toast.update('update-item-' + action.payload.data.item_id, { render: action.payload.data.name + " updated!", type: toast.TYPE.SUCCESS, autoClose: 3500 });
            indexOfItem = state.items.results.findIndex(x => x.item_id === action.payload.data.item_id);
            return update(state, {
                items: {
                    results: {
                        [indexOfItem]: {
                            $set: action.payload.data
                        }
                    },
                },
                current: {
                    $set: action.payload.data
                },
                isLoading: { $set: false },
                isUpdating: { $set: false }
            });
        case DELETE.SUCCESS:
            toast.update('delete-item', { render: "Item deleted!", type: toast.TYPE.WARNING, autoClose: 3500 });
            indexOfItem = state.items.results.findIndex(x => x.item_id === action.payload.request.item_id);
            return update(state, {
                isUpdating: { $set: false },
                items: {
                    results: { $unset:  [indexOfItem]},
                }
            });

        default:
            return state;
    }
}
