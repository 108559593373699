import { toast } from "react-toastify";
import apiClient from "../../../constants/apiClient";
import { ROOT_URL } from "../../../constants/urls";

export function postClaim(post, validate = false) {
  return function (dispatch) {
    if (validate) {
      dispatch({ type: "CLAIM_IS_SUBMITTING", payload: true });
      apiClient.post(`${ROOT_URL}api/claims`, post).then(response => {
        const data = response.data;
        if (data.message) {
          toast.info(data.message);
        }
        dispatch({ type: "POST", payload: data });
      }).catch((error) => {
        // error
      }).then(() => {
        // always executed
        dispatch({ type: "CLAIM_IS_SUBMITTING", payload: false });
      })

    } else {
      dispatch({ type: "POST", payload: post });
    }
  };
}

