import React from "react";
import { useSelector } from "react-redux";
import WarrantyRequestPanel from './WarrantyRequestPanel';
export default function Panel() {
  const { current } = useSelector(state => state.signals);

  if (!current || !( current.signal_id > 0 ) ) {
    return null
  }
  const getType = () => {

    switch (current.type) {
      case 'warranty-request':
        return (
          <WarrantyRequestPanel key={current.signal_id} />
        );
      default:
        return;
    }
  }
  return (<div className="col-12 h-100">
    <div className="bg-light p-4 h-100">
      {getType()}
    </div>
  </div>
  );
}
