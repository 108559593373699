/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { listCenters, updateCenter } from '../../../../../actions/Centers/actions';
import Pagination from '../../../../Helper/Pagination';

const Centers = () => {
  const dispatch = useDispatch();
  const { items, filter } = useSelector((state) => ({
    items: state.centers.items,
    filter: state.centers.filter,
  }));

  useEffect(() => {
    dispatch(listCenters());
  }, [dispatch]);

  const renderItems = () => {
    if (!items) return null;

    return items.results.map((item) => (
      <tr key={item.lely_center_id}>
        <td>{item.name}</td>
        <td>{item.city || '-'}</td>
        <td>{item.country || '-'}</td>
        <td css={css`width: 6rem;`} className="text-right">
          {forLNA(item)}
        </td>
        <td css={css`width: 6rem;`} className="text-right">
          {systemButtons(item)}
        </td>
        <td css={css`width: 6rem;`} className="text-right">
          {statusButtons(item)}
        </td>
        <td>{editButton(item)}</td>
      </tr>
    ));
  };

  const updateCenterField = (item, key, value) => {
    const updatedItem = { ...item, [key]: value };
    dispatch(updateCenter(updatedItem));
  };

  const systemButtons = (item) => {
    const systems = ['SMART', 'SOMINT', 'COMPASS_QR']; // Array of systems
    const currentIndex = systems.indexOf(item.system); // Find the current system index
    const nextIndex = (currentIndex + 1) % systems.length; // Get the next system in the array (looping back to 0 if at the end)
    const nextSystem = systems[nextIndex]; // Get the next system

    return (
      <Button
        size="sm"
        block
        className="text-uppercase"
        onClick={() => updateCenterField(item, 'system', nextSystem)} // Update with the next system
      >
        {item.system}
      </Button>
    );
  };


  const statusButtons = (item) => {
    const other_status = item.status === 'ACTIVE' ? 'BLOCKED' : 'ACTIVE';
    const color = item.status === 'ACTIVE' ? 'success' : 'warning';
    return (
      <Button
        size="sm"
        block
        className="text-uppercase"
        color={color}
        onClick={() => updateCenterField(item, 'status', other_status)}
      >
        {item.status}
      </Button>
    );
  };

  const forLNA = (item) => {
    const color = item.is_lna ? 'success' : 'light';
    return (
      <Button
        size="sm"
        className="text-uppercase mr-1"
        css={css`width: 6rem;`}
        color={color}
        onClick={() => updateCenterField(item, 'is_lna', !item.is_lna)}
      >
        LNA
      </Button>
    );
  };

  const editButton = (item) => (
    <Link className="btn btn-secondary btn-sm" to={`/_admin/centers/${item.lely_center_id}`}>
      <FontAwesomeIcon icon={['far', 'pencil']} />
    </Link>
  );

  const onPageChanged = (data) => {
    const { currentPage, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    dispatch(listCenters({ offset }));
  };

  const updateLimit = (limit) => {
    dispatch(listCenters({ offset: 0, limit }));
  };

  const changeOrder = (order) => {
    dispatch(listCenters({ ordering: order }));
  };

  const columnHeader = (text, slug) => {
    let action = slug;
    let icon = false;
    if (filter.ordering === slug) {
      action = `-${slug}`;
      icon = 'chevron-up';
    } else if (filter.ordering === `-${slug}`) {
      action = slug;
      icon = 'chevron-down';
    }
    return (
      <span onClick={() => changeOrder(action)}>
        {getIcon(icon)}
        {text}
      </span>
    );
  };

  const getIcon = (icon) => {
    if (icon) {
      return <FontAwesomeIcon icon={['far', icon]} />;
    }
    return null;
  };

  if (!items || items.count === 0) {
    return null;
  }

  return (
    <div>
      <Table borderless size="sm" responsive>
        <tbody>
          <tr className="font-weight-bold py-2">
            <th>{columnHeader('Name', 'name')}</th>
            <th>{columnHeader('City', 'city')}</th>
            <th>{columnHeader('Country', 'country')}</th>
            <th className="text-center">{columnHeader('LNA', 'is_lna')}</th>
            <th className="text-center">{columnHeader('System', 'system')}</th>
            <th className="text-center">{columnHeader('Status', 'status')}</th>
          </tr>
        </tbody>
        <tbody>{renderItems()}</tbody>
      </Table>
      <Row className="align-items-center pt-1">
        <Col md="4" className="py-2">
          <Pagination
            totalRecords={items.count}
            pageLimit={filter.limit}
            pageNeighbours={1}
            onPageChanged={onPageChanged}
          />
        </Col>
        <Col md="4" className="text-center py-2">
          <small>
            {items.results.length} of {items.count} results
          </small>
        </Col>
        <Col md="4" className="text-center text-md-right col-sm py-2">
          <div className="btn-group-sm" role="group" aria-label="limit">
            {[25, 50, 100, 200].map((i) => (
              <button
                key={i}
                type="button"
                onClick={() => updateLimit(i)}
                className={`btn btn-outline-dark ${i === filter.limit ? 'active' : ''}`}
              >
                {i}
              </button>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Centers;
