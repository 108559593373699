import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Button, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
// import { listAtronLocation, createAtronLocation, selectAtronLocation } from "../../../actions/AtronLocations/actions";
import { createCenter, getCenter, updateCenter, updateCenterTemp } from "../../../../../actions/Centers/actions";
import ButtonSpinner from "../../../../Forms/ButtonSpinner";
const initialState = {
    name: '', //models.TextField(max_length=128, blank=True, null=True, unique=True)
    address: '', //models.TextField(max_length=128, blank=True, null=True)
    city: '', //models.TextField(max_length=128, blank=True, null=True)
    state: '', //models.TextField(max_length=128, blank=True, null=True)
    postalcode: '', //models.TextField(max_length=128, blank=True, null=True)
    country: '', //models.TextField(max_length=128, blank=True, null=True)
    phone: '', //models.TextField(max_length=128, blank=True, null=True)
    system: '', //models.CharField(choices=SYSTEM_CHOICES, max_length=100, default=SMART)
    is_international: false, //models.BooleanField(default=False)
    is_lna: false, //models.BooleanField(default=False)

}

function Form(props) {
    const centers = useSelector(state => state.centers);
    const current = useSelector(state => state.centers.current);
    const dispatch = useDispatch();
    // const [state, dispatchLocal] = useReducer(reducer, initialState)
    var { lely_center_id } = props;
    useEffect(() => {
        if (lely_center_id > 0) {
            dispatch(getCenter(lely_center_id));
        } else {
            dispatch(updateCenterTemp(initialState))
        }
    }, [dispatch, lely_center_id]);

    // input field
    const onChange = (e) => {
        var post = {
            ...current,
            [e.target.name]: e.target.value
        }
        dispatch(updateCenterTemp(post))

    }
    const onRadio = (name, value) => {
        var post = {
            ...current,
            [name]: value
        }
        dispatch(updateCenterTemp(post))
    }

    const submit = () => {
        if (current.lely_center_id > 0) {
            dispatch(updateCenter(current))
        } else {
            dispatch(createCenter(current));
        }
    }
    const { name, address, city, state, postalcode, country, phone, system, is_international, is_lna, status, id } = current
    const { isUpdating } = centers;
    return (
        <Container className="py-5">
            <Row className="justify-content-center py-3">
                <Col lg="9" >
                    <h5 className="text-muted">{current.lely_center_id > 0 ? 'Center ' + current.lely_center_id : 'New Center'}</h5>
                    <FormGroup>
                        <Label>id</Label>
                        <Input type="text" placeholder="ID" value={id} onChange={onChange} id="id" name="id" />
                    </FormGroup>
                    <FormGroup>
                        <Label>Name</Label>
                        <Input type="text" placeholder="Name" value={name} onChange={onChange} id="name" name="name" />
                    </FormGroup>
                    <FormGroup>
                        <Label>Address</Label>
                        <Input type="text" placeholder="Address" value={address} onChange={onChange} id="address" name="address" />
                    </FormGroup>
                    <FormGroup>
                        <Label>City</Label>
                        <Input type="text" placeholder="City" value={city} onChange={onChange} id="city" name="city" />
                    </FormGroup>
                    <FormGroup>
                        <Label>State</Label>
                        <Input type="text" placeholder="State" value={state} onChange={onChange} id="state" name="state" />
                    </FormGroup>
                    <FormGroup>
                        <Label>Postalcode</Label>
                        <Input type="text" placeholder="Postalcode" value={postalcode} onChange={onChange} id="postalcode" name="postalcode" />
                    </FormGroup>
                    <FormGroup>
                        <Label>Country</Label>
                        <Input type="text" placeholder="Country" value={country} onChange={onChange} id="country" name="country" />
                    </FormGroup>
                    <FormGroup>
                        <Label>Phone</Label>
                        <Input type="text" placeholder="Phone" value={phone} onChange={onChange} id="phone" name="phone" />
                    </FormGroup>
                    <FormGroup>
                        <Label>System</Label>
                        <div>
                            <Button color={system === 'SOMINT' ? 'success' : 'light'} outline={system !== 'SOMINT'} onClick={() => onRadio("system", 'SOMINT')} >SOMINT</Button>
                            <Button color={system === 'SMART' ? 'success' : 'light'} outline={system !== 'SMART'} className="ml-2" onClick={() => onRadio("system", 'SMART')} >SMART</Button>
                            <Button color={system === 'COMPASS_QR' ? 'success' : 'light'} outline={system !== 'COMPASS_QR'} className="ml-2" onClick={() => onRadio("system", 'COMPASS_QR')} >COMPASS_QR</Button>
                            {/* <Button color={system === 'COMPASS_BARCODE' ? 'success' : 'light'} outline={system !== 'COMPASS_BARCODE'} className="ml-2" onClick={() => onRadio("system", 'COMPASS_BARCODE')} >COMPASS_BARCODE</Button> */}
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Label>Can submit claims?</Label>
                        <div>
                            <Button color={status === 'ACTIVE' ? 'success' : 'light'} outline={status !== 'ACTIVE'} onClick={() => onRadio("status", 'ACTIVE')} >ACTIVE</Button>
                            <Button color={status === 'BLOCKED' ? 'warning' : 'light'} outline={status !== 'BLOCKED'} className="ml-2" onClick={() => onRadio("status", 'BLOCKED')} >BLOCKED</Button>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Label>Is center from LNA?</Label>
                        <div>
                            <Button color={is_lna ? 'success' : 'light'} outline={!is_lna} onClick={() => onRadio("is_lna", true)} >Yes</Button>
                            <Button color={!is_lna ? 'success' : 'light'} outline={is_lna} className="ml-2" onClick={() => onRadio("is_lna", false)} >No</Button>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Label>Can handle claims?</Label>
                        <div>
                            <Button color={is_international ? 'success' : 'light'} outline={!is_international} onClick={() => onRadio("is_international", true)} >Yes</Button>
                            <Button color={!is_international ? 'success' : 'light'} outline={is_international} className="ml-2" onClick={() => onRadio("is_international", false)} >No</Button>
                        </div>
                    </FormGroup>
                    <div className="py-3">
                        <Row>
                            <Col>
                                <Link to="/_admin/centers" className="btn btn-light btn-block">Cancel</Link>
                            </Col>
                            <Col>
                                <ButtonSpinner color="primary" loading={isUpdating} className="btn-block" onClick={() => submit()} >Save</ButtonSpinner>
                            </Col>
                        </Row>
                    </div>
                </Col>

            </Row>
        </Container>
    )
}

export default Form;
