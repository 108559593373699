import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Col, Container, Row } from "reactstrap";
import { listSignals } from "../../../actions/Signal/actions";
import { Loading } from "../../Helper/Loading";
import Filter from "./components/Filter";
import Panel from "./components/Panel/";
import Signals from "./components/Signals";



export default function Inbox() {
  const dispatch = useDispatch();
  const items = useSelector(state => state.signals.items);
  const isLoading = useSelector(state => state.signals.isLoading);
  useEffect(() => {
    dispatch(listSignals({user__center__is_lna: null, blank_warranty_request: 0}))

  }, [dispatch]);
  return (
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Filter />
        </Col>
      </Row>
      <Row className="no-gutters">
        {!items && <Col><Loading /></Col>}
        {items.results && items.results.length === 0 && isLoading && <Col><Loading /></Col> }
        {items.results && items.results.length === 0 && !isLoading &&
          <Col>
            <Alert color="warning">
              No claims yet
            </Alert>
          </Col>
        }
      </Row>
      {items && items.results.length > 0 &&
      <Row>
        <Col key="col-signals" lg="7" md="12">
          <Signals />
        </Col>
        <Col key="col-panel" lg="5" md="12">
          <Panel />
        </Col>
      </Row>
      }
    </Container>

  )
}
