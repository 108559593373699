import React, {Component} from 'react';
import Select from 'react-select';
import { connect } from "react-redux";
import {getTheme} from "../../../../constants/SelectTheme";


class StatusSelect extends Component {
  handleChange = value => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange(value);
  };

  getOptions(){

    return [
      {
        label: 'OPEN',
        value: 'open-accept,open-decline,open-review'
      },
      {
        label: 'ACCEPTED',
        value: 'closed-accepted'
      },
      {
        label: 'DECLINED',
        value: 'closed-declined'
      },
      {
        label: 'ALL',
        value: ''
      },
    ]
  }

  render() {
    return (
      <div >
        <label htmlFor="status">{this.props.label}</label>
        <Select
          id="status"
          options={this.getOptions()}
          isMulti={this.props.isMulti}
          name="status"
          search={this.props.search}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          isOptionDisabled={this.props.disabled}
          isClearable={true}
          theme={(theme) =>{return getTheme(theme)}}

        />
        {!!this.props.error &&
          this.props.touched && (
            <div className="invalid-feedback d-block">{this.props.error}</div>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {
       // items: state.inbox.users,
    }
}


function mapDispatchToProps(dispatch) {
    return {
        // getItems : bindActionCreators(getUsers, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps )(StatusSelect);
