import React, {Component} from 'react';
import {Button} from "reactstrap";
import PropTypes from 'prop-types';
class Spinner extends Component {
  render(){
        return <span className="spinner"><span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span></span>
    
  }
}
class ButtonSpinner extends Component{

  render(){
    const { disabled, loading, ...rest } = this.props;
    return <Button {...rest} disabled={disabled || loading} >
                {loading &&
                <Spinner /> 
              }
                {this.props.children}
              </Button>
    }
  }
  ButtonSpinner.propTypes = {
    loading: PropTypes.bool
  }
  ButtonSpinner.defaultProps = {
    loading: false,
  };

export default ButtonSpinner;
