import update from 'immutability-helper';
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { bindActionCreators } from 'redux';
import ButtonSpinner from "../../../Forms/ButtonSpinner";
import { postClaim } from '../actions';
import Box from "./Box";
import Header from "./Header";
import InputMeta from "./InputMeta";

class ResultClaim extends Component {
    constructor(props) {
        super(props);
        this.state = {
            manual_prefix_disabled: props.post.scanned_prefix,
            manual_serial_disabled: props.post.scanned_serial,
            manual_prefix: props.post.manual_prefix,
            manual_serial: props.post.manual_serial
        };
    }

    goBack() {
        var { post, postClaim } = this.props;
        var index = post.item.requested_meta.reverse().findIndex( v => v.use_OCR || v.picture_only );
        var prevStep = index > -1 ? 'meta_ocr_'+index : 'validate_label';
        console.log('prev_step', prevStep, index, post.item.requested_meta);
        post = update(this.props.post, { step: { $set: prevStep } });
        postClaim(post);
    }
    submit(values) {
        var { post, postClaim } = this.props;
        postClaim(post, true);
    }

    render() {
        var { post, isSubmitting } = this.props;
        return (
            <Box>
                <Header name="Results" {...this.props} />
                {post.item.requested_meta.map(meta =>
                    <InputMeta key={meta.item_meta_id} meta={meta} />
                )}
                <Row>
                    <Col>
                        <Button color="secondary" className="btn-block" onClick={() => this.goBack()}>Back</Button>
                    </Col>
                    <Col>
                        <ButtonSpinner color="primary" loading={isSubmitting} className="btn-block" onClick={() => this.submit()}>Submit</ButtonSpinner>
                    </Col>
                </Row>
                <Row className="pt-3">
                    <Col>
                        <span className="text-small text-muted">Traceback code: {post.traceback}</span>
                    </Col>
                </Row>
            </Box>

        );
    }
}


function mapStateToProps(state) {
    return {
        post: state.addclaim.post,
        isSubmitting: state.addclaim.isSubmitting
    }
}

function mapDispatchToProps(dispatch) {
    return {
        postClaim: bindActionCreators(postClaim, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultClaim);
