import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import update from 'immutability-helper';
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Input, InputGroup, InputGroupAddon, Label } from "reactstrap";
import { bindActionCreators } from 'redux';
import { postClaim } from '../actions';

class InputMeta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: props.meta.is_scanned,
            manual: props.meta.manual,
        };
    }
    handleChange(value) {
        var { post, postClaim, meta } = this.props;
        value = value.toUpperCase();
        this.setState({manual: value});
        var newMeta = {...meta};
        newMeta.manual = value;

        var requested_meta = post.item.requested_meta;
        var index = requested_meta.findIndex(x => x.item_meta_id === meta.item_meta_id);
        requested_meta[index] = newMeta; 
        post = update(this.props.post, { item:  { requested_meta: { $set:  requested_meta  } } });
        postClaim(post);
    }
    getInput() {
        var { meta } = this.props;
        if (this.state.disabled) {
            return <Input key={'scanned_' + meta.name} type="text" disabled={true} value={meta.scanned} name={'scanned_' + meta.name}  />
        } else {
            return <Input key={'manual_' + + meta.name} type="text" placeholder={meta.scanned} name={'manual_' + meta.name} value={this.state.manual} onChange={(e) => this.handleChange( e.target.value)} />
        }

    }
    render() {
        var { meta } = this.props;
        return (
            <fieldset className="form-group">
                <div>
                    <Label className="d-block">{this.state.disabled ? 'Scanned '+meta.name : 'Manual '+meta.name}</Label>
                </div>
                <InputGroup>
                    {this.getInput()}
                    <InputGroupAddon addonType="append" className={!meta.is_scanned ? 'd-none' : ''}>
                        <Button color={this.state.disabled ? 'warning' : 'danger'} onClick={() => this.setState({disabled: !this.state.disabled}) }>
                            <FontAwesomeIcon icon={['far', this.state.disabled ? 'pencil' : 'times']} />
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </fieldset>
        )
    }
}

function mapStateToProps(state) {
    return {
        post: state.addclaim.post,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        postClaim: bindActionCreators(postClaim, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputMeta);
