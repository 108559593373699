import React from "react";
import 'react-app-polyfill/ie11';
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// import "bootstrap/dist/css/bootstrap.scss";
import 'redux-notifications/lib/styles.css';
import App from "./components/App";
import * as serviceWorker from './serviceWorker';
import store from "./store";
import "./styles/style.scss";
// import history from "./utils/historyUtils";

ReactDOM.render(
    <Provider store={store}>
            <App />
    </Provider>
    , document.getElementById("root"));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();