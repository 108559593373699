/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink as RRNavLink, Outlet, useParams } from "react-router-dom";
import { Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";

// import AddWRNumber from "./AddWRNumber";
// import LelyCenters from "./LelyCenters";
// import LelyItems from "./LelyItems";


const getUrls = (user) => [
    user.is_lely_superuser && {
        'path': 'centers/:lely_center_id?',
        'slug': 'centers',
        'name': 'Centers',
    },
    user.is_lely_superuser &&
    {
        'path': 'items/:item_id?',
        'slug': 'items',
        'name': 'Items',
    },
    (user.is_lely_superuser || user.is_lna_superuser) &&
    {
        'path': 'add-wr-number',
        'slug': 'add-wr-number',
        'name': 'Add WR numbers',
    },
    (user.is_lely_superuser || user.is_lna_superuser) &&
    {
        'path': 'export-csv',
        'slug': 'export-csv',
        'name': 'Export .csv',
    },
    (user.is_lely_superuser || user.is_lna_superuser) &&
    {
        'path': '/_admin',
        'slug': '/_admin',
        'name': 'Admin',
    },
]

const Admin = props => {
    const { sub_action } = useParams();
    const action = sub_action || 'centers'
    const user = useSelector(state => state.auth.user)
    const urls = getUrls(user);


    return (
        <div>
            <Container >
                <Row>
                    <Col md={2} className="pt-md-5 pr-2" >
                        <Nav vertical className="bg-light w-100" css={css`min-height: 75vh;`}>
                            {urls.filter(i => i && i.slug !== '/_admin').map((i) => {
                                console.log(i)
                                return (

                                    <NavItem key={i.slug} active={action === i.slug}>
                                        <NavLink tag={RRNavLink} exact to={i.slug} activeClassName="active">{i.name}</NavLink>
                                    </NavItem>
                                )
                            }
                            )}
                        </Nav>
                    </Col>
                    <Col md={10} className="pt-5" >
                        <Outlet></Outlet>
                    </Col>
                </Row>

            </Container>
        </div>
    );
};
export default Admin



