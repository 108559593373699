import { labels, post } from "./constants";

const addClaim = {
    post: post,
    isSubmitting: false,
    labels: labels.SOMINT
};

  export default function reducer(state = addClaim, action) {
      switch(action.type) {
          case "USER":
                const newLabels = action.payload.center && labels[action.payload.center.system] ? labels[action.payload.center.system] : labels.SOMINT
                return {...state, labels: newLabels}
          case "CURRENT_STEP":
                return { ...state, step: action.payload};
          case "POST":
                return {...state, post: action.payload}
          case "CLAIM_IS_SUBMITTING":
                  return { ...state, isSubmitting: action.payload};
          default:
               return state;
      }
  }
