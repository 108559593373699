/** @jsxImportSource @emotion/react */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollArea from 'react-scrollbar';
import { Badge, Col, Row } from "reactstrap";
import { listSignals, selectSignal } from '../../../../actions/Signal/actions';
import { Loading } from '../../../Helper/Loading';
import Pagination from '../../../Helper/Pagination';
import StatusBadge from '../../../Helper/StatusBadge';
import { updateItem } from '../actions';


export default function Signals() {
  const dispatch = useDispatch();
  // const navigate = useNavigate()
  const items = useSelector(state => state.signals.items)
  const isLoading = useSelector(state => state.signals.isLoading)
  const currentSignal = useSelector(state => state.signals.current)
  const user = useSelector(state => state.auth.user)
  // const labels = useSelector(state => state.addclaim.labels)
  const filter = useSelector(state => state.signals.filter)

  const renderItems = () => {

    if (!items || items.count === 0) {
      return null;
    }
    return (
      <Row>
        {items.results.map(item => {
          var className = 'align-items-center signals__item py-2 small  justify-content-end';
          if (currentSignal.signal_id === item.signal_id) {
            className += ' -active';
          }
          if (user.center.is_international || user.is_lna_superuser) {
            // lely international
            return (
              <Col key={item.signal_id} xs="12" sm="6" lg="12">
                <Row className={className}  >
                  <Col className="d-none d-lg-block" md="2" lg="2">{item.date}</Col>
                  <Col className="d-none d-lg-block" md="2" lg="2">{item.user.center.city || '-'}</Col>
                  <Col className="d-none d-lg-block" md="2" lg="2" >
                    {item.warranty_request || '-'} / {item.a_number}
                  </Col>
                  <Col className="d-none d-lg-block text-left" md="3" lg="2" > {item.item_number}</Col>
                  <Col className="d-none d-lg-block text-right" md="3" lg="4" >
                    <Badge className="py-2 px-3 mr-1 text-uppercase" onClick={() => { dispatch(selectSignal(item)) }}>
                      <FontAwesomeIcon icon={['far', 'eye']} />
                    </Badge>
                    <StatusBadge status={item.status} handleClick={() => { updateStatus(item) }} />
                  </Col>
                  {/* on mobile */}
                  <Col sm="12" className="d-block d-lg-none">
                    <Row className="py-2">
                      <Col><b>Date</b></Col>
                      <Col xs="8">{item.date}</Col>
                    </Row>
                    <Row className="py-2">
                      <Col><b>Center</b></Col>
                      <Col xs="8">{item.user.center.city || 'Unknown city'}</Col>
                    </Row>
                    <Row className="py-2">
                      <Col><b>WR number</b></Col>
                      <Col xs="8">{item.warranty_request || '-'}</Col>
                    </Row>
                    <Row className="py-2">
                      <Col><b>A number</b></Col>
                      <Col xs="8">{item.a_number}</Col>
                    </Row>
                    <Row className="py-2">
                      <Col><b>Item number</b></Col>
                      <Col xs="8">{item.item_number}</Col>
                    </Row>
                    <Row className="align-items-center py-2">
                      <Col><b>Status</b></Col>
                      <Col xs="8">
                        <Badge className="py-2 px-3 mr-1 text-uppercase" onClick={() => { dispatch(selectSignal(item)) }}>
                          <FontAwesomeIcon icon={['far', 'eye']} />
                        </Badge>
                        <StatusBadge status={item.status} handleClick={() => { updateStatus(item) }} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            )
          } else {
            // lely center
            return (
              <Col key={item.signal_id} xs="12" sm="6" md="12">
                <Row className={className}  >
                  <Col className="d-none d-md-block" md="2" lg="2">{item.date}</Col>
                  <Col className="d-none d-md-block" md="3" lg="3" >
                    {item.warranty_request || '-'} / {item.a_number}
                  </Col>
                  <Col className="d-none d-md-block text-left" md="3" lg="3" > {item.item_number}</Col>
                  <Col className="d-none d-md-block text-right" md="4" lg="4"  >
                    <Badge className="py-2 px-3 mr-1 text-uppercase" onClick={() => { dispatch(selectSignal(item)) }}>
                      <FontAwesomeIcon icon={['far', 'eye']} />
                    </Badge>
                    <StatusBadge status={item.status} handleClick={() => { updateStatus(item) }} />
                  </Col>
                  {/* on mobile */}
                  <Col sm="12" className="d-block d-md-none">
                    <Row className="py-2">
                      <Col><b>Date</b></Col>
                      <Col xs="8">{item.date}</Col>
                    </Row>
                    <Row className="py-2">
                      <Col><b>WR number</b></Col>
                      <Col xs="8">{item.warranty_request || '-'}</Col>
                    </Row>
                    <Row className="py-2">
                      <Col><b>A number</b></Col>
                      <Col xs="8">{item.a_number}</Col>
                    </Row>
                    <Row className="py-2">
                      <Col><b>Item number</b></Col>
                      <Col xs="8">{item.item_number}</Col>
                    </Row>
                    <Row className="align-items-center py-2">
                      <Col><b>Status</b></Col>
                      <Col xs="8">
                        <Badge className="py-2 px-3 mr-1 text-uppercase" onClick={() => { dispatch(selectSignal(item)) }}>
                          <FontAwesomeIcon icon={['far', 'eye']} />
                        </Badge>
                        <StatusBadge status={item.status} handleClick={() => { updateStatus(item) }} />
                      </Col>
                    </Row>
                  </Col>
                </Row >
              </Col>
            )
          }
        })}

      </Row>
    )
  }


  const updateStatus = (item) => {
    if (item.status === 'open-accept' || item.status === 'open-decline') {
      item.status = item.attributes.proposed_status.input;
      dispatch(updateItem(item))
    }
    if (item.status === 'open-review') {
      dispatch(selectSignal(item))
    }
  }

  const onPageChanged = data => {
    const { currentPage, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    dispatch(listSignals({ offset }))
  };

  const updateLimit = (limit) => {
    dispatch(listSignals({ offset: 0, limit: limit }))
  }
  const changeOrder = (order) => {
    dispatch(listSignals({ ordering: order }))
  }
  const columnHeader = (text, slug) => {
    var action = slug;
    var icon = false;
    if (filter.ordering === slug) {
      action = '-' + slug;
      icon = 'chevron-up';
    } else if (filter.ordering === '-' + slug) {
      action = slug;
      icon = 'chevron-down';
    }
    return (<span onClick={() => changeOrder(action)}>{getIcon(icon)}{text}</span>)
  }
  const getIcon = (icon) => {
    if (icon) {
      return (<FontAwesomeIcon icon={['far', icon]} />)
    }
    return null;
  }

  if (!items || typeof user.center === 'undefined') {
    return null;
  }

  return (
    <div >
      {(() => {
        if (user.center.is_international || user.is_lna_superuser) {
          return (
            <Row className="text-uppercase font-weight-bold py-2  d-none d-lg-flex small">
              <Col md="2" lg="2" className="">{columnHeader('Date', 'created')}</Col>
              <Col md="2" lg="2" >{columnHeader('Center', 'user__center__name')}</Col>
              <Col md="2" lg="2" className="">{columnHeader('Request', 'warranty_request')}</Col>
              <Col md="3" lg="2" className="text-left">{columnHeader('Item number', 'item_number')}</Col>
              <Col md="3" lg="4" className=" text-center">{columnHeader('Status', 'status')}</Col>
            </Row>
          )
        } else {
          return (
            <Row className="text-uppercase font-weight-bold py-2  d-none d-md-flex small">
              <Col sm="2" md="2" lg="2" className="">{columnHeader('Date', 'created')}</Col>
              <Col sm="4" md="3" lg="3" className="">{columnHeader('Request', 'warranty_request')}</Col>
              <Col sm="3" md="3" lg="3" className="text-left">{columnHeader('Item number', 'item_number')}</Col>
              <Col xs="0" md="4" lg="4" className=" text-center">{columnHeader('Status', 'status')}</Col>
            </Row>
          )
        }
      })()}

      <ScrollArea
        speed={0.8}
        className="signals__area"
        contentClassName="content"
        horizontal={false}
      >
        {renderItems()}
      </ScrollArea>

      {isLoading && <Loading></Loading>}
      <Row className={"align-items-center pt-1 " + (isLoading && 'd-none') }>
        <Col md="4" className="py-2">
          <Pagination
            totalRecords={items.count}
            pageLimit={filter.limit}
            pageNeighbours={1}
            onPageChanged={onPageChanged}
          />
        </Col>
        <Col md="4" className="text-center py-2">
         <small>
            {items.results.length} of {items.count} results
          </small>
        </Col>
        <Col md="4" className="text-center text-md-right col-sm py-2">
          <div className="btn-group-sm" role="group" aria-label="limit">
            {[25, 50, 100, 200].map(i => {
              return (<button key={i} type="button" onClick={() => updateLimit(i)} className={'btn btn-outline-dark ' + (i === filter.limit ? 'active' : '')}>{i}</button>)
            })}
          </div>
        </Col>
      </Row>

    </div>

  );

}
