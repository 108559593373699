import store from '../../store';
import { CREATE, GET, LIST, SIGNALS, UPDATE, BULK_UPDATE, DELETE } from './actionsTypes';
const endpoint = 'signals/';


export function listSignals(filter = {}) {
  const state = store.getState();
  filter = { ...state.signals.filter, ...filter };
  return {
    types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
    payload: {
      request: {
        filter_object: filter,
        params: filter_to_params(filter),
        method: 'GET',
        url: endpoint
      }
    }
  }
}

export function changeFilter(data){
  const state = store.getState();
  data = { ...state.signals.filter, ...data };
  return {
    type: LIST.FILTER,
    payload:  data
  }
}

export function resetFilter(data){
  return {
    type: LIST.FILTER,
    payload:  data
  }
}


function filter_to_params(filter) {
  filter = {
    ...filter,
    type: filter.type ? filter.type.map(i => i.value).join(',') : '',
    signal_id: filter.signal_id ? filter.signal_id.join(',') : '',
    // status: filter.status ? filter.status.map(i => i.value).join(',') : '',
    status: filter.status ? filter.status.value : '',
    is_lna: filter.is_lna ? filter.is_lna.value : '',
    item_number: filter.item_number ? filter.item_number.value : '',
    user_id: filter.user_id ? filter.user_id.map(i => i.value).join(',') : '',
  }


  return Object.fromEntries(Object.entries(filter).filter(([_, v]) => v != null && v !== ''));
}
export function selectSignal(signal) {
  return {
    type: SIGNALS.SELECT,
    payload: { data: signal }
  }

}
export function getSignal(signal_id) {

  return {
    types: [GET.LOADING, GET.SUCCESS, GET.ERROR],
    payload: {
      request: {
        method: 'GET',
        url: endpoint + signal_id + '/',
      }
    }
  }
}

export function deleteSignal(signal_id) {

  return {
    types: [DELETE.LOADING, DELETE.SUCCESS, DELETE.ERROR],
    payload: {
      request: {
        method: 'DELETE',
        url: endpoint + signal_id + '/',
      }
    }
  }
}


export function createSignal(data) {
  return {
    types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
    payload: {
      request: {
        method: 'POST',
        url: endpoint,
        data: data
      }
    }
  }
}
export function updateSignalState(data) {
  return {
    type: UPDATE.STATE,
    payload: { data }
  }
}

export function updateSignalDetails(signal){

    return {
    types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
    payload: {
      request: {
        method: 'PUT',
        url: endpoint + signal.signal_id + '/update_details/',
        data: signal,
        signal_id: signal.signal_id
      },
    }
  }
}

export function updateSignalDetailsBulk(data){

  return {
  types: [BULK_UPDATE.LOADING, BULK_UPDATE.SUCCESS, BULK_UPDATE.ERROR],
  payload: {
    request: {
      method: 'PUT',
      url: endpoint + 'update_details/',
      data: data,
    },
  }
}
}

// export function updateSignal(signal, args = {}) {
//   args = {
//     status: false,
//     // update_related: '',
//     close_after_save: true,
//     outputs: {},
//     only_state: false,
//     force: false,
//     ...args
//   }

//   const state = store.getState();
//   let { is_nn_handler } = state.auth.user;

//   if (signal.status === 'NIEUW' && args.status === false && is_nn_handler) {
//     args.status = 'OPEN';
//   }
//   if ((signal.status === 'VERVALLEN' || signal.status === 'SUCCES') && args.force === false) {
//     return { type: null, payload: null };
//   }


//   var post = update(signal, {
//     status: { $set: args.status ? args.status : signal.status },
//     outputs: { $set: { ...signal.outputs, ...args.outputs } },
//   });
//   if (args.only_state) {
//     return updateSignalState(post);
//   }

//   return {
//     types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
//     payload: {
//       request: {
//         method: 'PUT',
//         url: endpoint + signal.signal_id + '/',
//         data: post,
//       },
//       close_after_save: args.close_after_save
//     }
//   }
// }

// export function deleteSignal(id) {
//   return {
//     types: [DELETE.LOADING, DELETE.SUCCESS, DELETE.ERROR],
//     payload: {
//       request: {
//         method: 'DELETE',
//         signal_id: id,
//         url: endpoint + id + '/'
//       }
//     }
//   }
// }
