import React from "react";
// import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { postClaim } from "./actions";
import LabelPicture from "./components/LabelPicture";
import MetaPicture from "./components/MetaPicture";
import ResultClaim from "./components/ResultClaim";
import ValidateLabel from "./components/ValidateLabel";
import { post } from "./constants";

export default function AddClaim() {
  const step = useSelector(state => state.addclaim.post.step);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {

    if (step === "finished" || step === "home") {
      // reset the form
      dispatch(postClaim(post))
      // and go back to inbox
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  if (step === "label_ocr") {
    return <LabelPicture />
  } else if (step === "validate_label") {
    return <ValidateLabel />

  } else if ((/^meta_ocr_\d{1,3}$/).test(step)) {
    return <MetaPicture />
  } else if (step === "validate_meta") {
    return <ResultClaim />
  }
  return null;

}
