import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Input, Label, Row } from "reactstrap";
import { listSignals } from "../../../../actions/Signal/actions";
import ItemSelect from "../../../Forms/ItemSelect";
import CenterSearch from "./CenterSearch";
import StatusSelect from "./StatusSelect";

export default function Filter(props) {
  const labels = useSelector(state => state.addclaim.labels)
  const filter = useSelector(state => state.signals.filter)
  const user = useSelector(state => state.auth.user)
  const [show, setShow] = useState(window.innerWidth > 760);
  const [dirty, setDirty] = useState({});
  const timer = useRef(null);
  const hideClass = show || "d-none";
  // const [filter, setFilter] = useState({ limit: 25, user_id: '', type: '', status: '', warranty_request: '', a_number: '', item_number: '', created: '', offset: 0, is_lna: { value: '', label: 'ALL' } });
  const dispatch = useDispatch();
  const addClaimButton = () => {
    if (typeof user.id === "undefined") {
      return null
    }
    if (user.center.is_international) {
      return null;
    }
    return (
      <Col xs="9" md="12" lg="1" className="d-lg-none" >
        <label className="d-none d-lg-block">&nbsp;</label>
        <Link className="btn btn-primary btn-block" to="/add-claim">Add Claim</Link>
      </Col>
    )
  }

    useEffect(() => {
        // useRef value stored in .current property
        timer.current = setTimeout(() => dispatch(listSignals(dirty)), 330);

        // clear on component unmount
        return () => {
            clearInterval(timer.current);
        };
    }, [dirty, dispatch]);


  return (
    <Row className="py-3 justify-content-between">
      <Col md="6" xs="12" lg="2" className={hideClass}>
        <Label>Date</Label>
        <Input type="text" value={dirty.created} onChange={(e) => setDirty({ created: e.target.value })} placeholder="YYYY-MM-DD"></Input>
      </Col>
      <Col md="6" xs="12" lg="2" className={hideClass}>
        <CenterSearch filter={filter} setFilter={(filter) => dispatch(listSignals(filter)) } />
      </Col>
      <Col md="6" xs="12" lg="2" className={hideClass}>
        <Label>{labels.warranty_request.label}</Label>
        <Input type="text" value={dirty.warranty_request} onChange={(e) => setDirty({ warranty_request: e.target.value })} placeholder=""></Input>
      </Col>
      <Col md="6" xs="12" lg="2" className={hideClass}>
        <Label>{labels.a_number.label}</Label>
        <Input type="text" value={dirty.a_number} onChange={(e) => setDirty({ a_number: e.target.value })} placeholder=""></Input>
      </Col>
      <Col md="6" xs="12" lg="2" className={hideClass}>
        <ItemSelect
          label="Item"
          name="item_number"
          id="item_number"
          onChange={(_,value) => dispatch(listSignals({ item_number: value }))}
          value={filter.item_number ? filter.item_number.value : ""}
        />
      </Col>
      <Col md="6" xs="12" lg="2" className={hideClass}>
        <StatusSelect
          label="Status"
          name="status"
          id="status"
          onChange={(value) => dispatch(listSignals({ status: value}))}
          value={filter.status ? filter.status.value : ""}
        />
      </Col>

      <Col xs="3" className="d-md-none ml-auto" >
        <label className="d-none d-lg-block">&nbsp;</label>
        <Button type="button" className="btn-block " onClick={() => setShow(!show)}>
          <FontAwesomeIcon icon={["far", "filter"]} />
        </Button>
      </Col>
      {addClaimButton()}
    </Row>
  )

}
