import React, { Component } from "react";
import { connect } from "react-redux";
import { Input, Col, Row } from "reactstrap";
import { bindActionCreators } from 'redux';
import { listCenters, syncCenters } from '../../../../../actions/Centers/actions';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class Filter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: ''
    };

  }
  updateSearch(search) {
    var post = {
      search: search,
      offset: 0,
    };
    this.setState({ 'search': search });
    return this.props.listCenters(post);
  }
  syncCenters = () => {
    this.props.syncCenters().then((response) => {
      toast.info(response.data);
      this.props.listCenters()
    })
  }
  render() {
    return (
      <Row className="py-3 justify-content-between">

        <Col sm="8" lg="9">
          <Input
            type="text"
            name="search"
            value={this.state.search}
            onChange={(e) => {
              this.updateSearch(e.target.value)
            }}
            placeholder="e.g. name, city or country"
          />
        </Col>
        <Col sm="4" lg="3" >
            <Link className="btn btn-secondary btn-block" to="/_admin/centers/new">Add</Link>
        </Col>
      </Row>
    );
  }
}


function mapStateToProps(state) {
  return {
    user: state.auth.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    listCenters: bindActionCreators(listCenters, dispatch),
    syncCenters: bindActionCreators(syncCenters, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
