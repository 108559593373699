import { defineAction } from 'redux-define';
import { TheApp, LOADING, ERROR, SUCCESS, FILTER, STATE, SELECT } from '../../constants/stateConstants';


export const SIGNALS = defineAction('SIGNALS', [LOADING, ERROR, SUCCESS, SELECT], TheApp);
export const LIST = defineAction('LIST', [ FILTER, LOADING, ERROR, SUCCESS], SIGNALS);
export const GET = defineAction('GET', [ LOADING, ERROR, SUCCESS], SIGNALS);
export const CREATE = defineAction('CREATE', [ LOADING, ERROR, SUCCESS], SIGNALS);
export const UPDATE = defineAction('UPDATE', [ LOADING, ERROR, SUCCESS, STATE], SIGNALS);
export const BULK_UPDATE = defineAction('BULK_UPDATE', [ LOADING, ERROR, SUCCESS, STATE], SIGNALS);
export const DELETE = defineAction('DELETE', [ LOADING, ERROR, SUCCESS], SIGNALS);
