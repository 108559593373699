const inbox = {
  users: [],
  items: false,
  activeItem: false,
  current: {},
  filter: {user_id: "", warranty_request: "", a_number: "", status: "", item_number: "", search: "", limit: 50, ordering: "-created" },
  currentView: "signal",
};
  export default function reducer(state = inbox, action) {
    let indexOfItem;
      switch(action.type) {
          case "LOAD_USERS":
                return { ...state, users: action.payload};
          case "LOAD_ITEMS":
                return { ...state, items: action.payload};
          case "UPDATE_FILTER":
                return { ...state, filter: action.payload};
          case "LOAD_APPLICATIONS":
                return { ...state, applications: action.payload};
          case "ACTIVE_ITEM":
                indexOfItem = state.items.results.findIndex(x => x.signal_id === action.payload);
                return { ...state, activeItem: action.payload, current: state.items.results[indexOfItem]};
          // case "UPDATE_ITEM":
          //       indexOfItem = state.items.results.findIndex(x => x.signal_id === action.payload.signal_id);
          //        return update(state, {
          //             items: {
          //               results: {
          //                 [indexOfItem]:{
          //                   $set: action.payload
          //                 }
          //               },
          //             },
          //             current: {
          //               $set: action.payload
          //             }
          //         });
          case "CURRENT_VIEW":
                return { ...state, currentView: action.payload};
          default:
               return state;
      }
  }
