/** @jsxImportSource @emotion/react */
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import apiClient from '../../../../constants/apiClient';
import { ROOT_URL } from '../../../../constants/urls';


const ExportCsv = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [status, setStatus] = useState("");
    const [selectedItemNumbers, setSelectedItemNumbers] = useState([]);
    const [isLNA, setIsLNA] = useState(false);
    const [itemNumbersOptions, setItemNumbersOptions] = useState([]);

    const formatDateToISO = (date) => {
        if (!date) return null;
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    };
    const handleItemChange = (selectedOptions) => {
        setSelectedItemNumbers(selectedOptions || []);
    };
    useEffect(() => {
        const fetchItemNumbers = async () => {
            try {

                const params = {
                    startDate: formatDateToISO(startDate),
                    endDate: formatDateToISO(endDate),
                    status,
                    isLNA: isLNA.toString()  // Convert the boolean to a string.
                };

                const response = await apiClient.post(`${ROOT_URL}api/export/items`, params);
                const fetchedItemNumbers = response.data.map(item => ({
                    value: item.item_id,
                    label: item.item_number
                }));


                setItemNumbersOptions(fetchedItemNumbers);
                setSelectedItemNumbers([]);
            } catch (error) {
                console.error('Failed to fetch item numbers', error);
            }
        };

        fetchItemNumbers();
    }, [startDate, endDate, status, isLNA]);


    const exportCSV = async () => {
        try {
            const itemsToExport = selectedItemNumbers.length ? selectedItemNumbers.map(item => item.value) : itemNumbersOptions.map(item => item.value);
            const response = await apiClient.post(`${ROOT_URL}api/export/csv`, {
                startDate: formatDateToISO(startDate),
                endDate: formatDateToISO(endDate),
                status,
                selectedItemNumbers: itemsToExport,
                isLNA,
            }, {
                responseType: 'blob'
            });

            const blob = new Blob([response.data], { type: 'text/csv' });
            const downloadUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'export.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Failed to export CSV', error);
        }
    };

    return (
        <div className="container mt-5">
            <div className="mb-3 row">
                <label className="form-label col-md-2">Start Date:</label>
                <div className="col-md-10">
                    <DatePicker
                        className="form-control"
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        dateFormat="yyyy/MM/dd"
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <label className="form-label col-md-2">End Date:</label>
                <div className="col-md-10">
                    <DatePicker
                        className="form-control"
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        dateFormat="yyyy/MM/dd"
                    />
                </div>
            </div>
            <div className="mb-3 row">
                <label className="form-label col-md-2">Status:</label>
                <div className="col-md-10">
                    <select className="form-select" value={status} onChange={e => setStatus(e.target.value)}>
                        <option value="">Select Status</option>
                        <option value="open">OPEN</option>
                        <option value="accepted">ACCEPTED</option>
                        <option value="declined">DECLINED</option>
                        <option value="status4">ALL</option>
                    </select>
                </div>
            </div>
            <div className="mb-3 row">
                <label className="form-label col-md-2">LNA:</label>
                <div className="col-md-10 d-flex align-items-center pl-4">
                    <input type="checkbox" className="form-check-input me-2" id="lnaCheckbox" checked={isLNA} onChange={e => setIsLNA(e.target.checked)} />
                    <label className="form-check-label" htmlFor="lnaCheckbox">True</label>
                </div>
            </div>
            <div className="mb-3 row">
                <label className="form-label col-md-2">Item Numbers:</label>
                <div className="col-md-10">
                    <Select
                        isMulti
                        value={selectedItemNumbers}
                        options={itemNumbersOptions}
                        onChange={handleItemChange}
                    />
                </div>
            </div>
            <div className="mb-3">
                <button className="btn btn-primary" onClick={exportCSV}>Export .csv</button>
            </div>
        </div>
    );
}

export default ExportCsv;
