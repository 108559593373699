import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { listItems } from "../../actions/Items/actions";
import SelectWrapper from "./SelectWrapper";
class ItemSelect extends SelectWrapper {

    static defaultProps = {
        disabled: false,
        isClearable: true,
        search: true,
        showLabel: true,
        showError: true,
        onBlur: () => { },
        mapOptions: (item) => { return { label: item.name + ": " + item.item_number, value: item.item_number } },
        getItems: () => [],
        defaultValue: null,
        value: null
    }

    componentDidMount() {
        const {getItems} = this.props;
        getItems({ "limit": 300});
      }

    fromValueToObject(value){
        const { items, mapOptions } = this.props;
        // console.log('fromValueToObject', value, items);
        if (!items) {
          return null;
        }
        if(!items.results.length === 0){
            return null;
        }
        if(!value ){
            return null;
        }
        if ( typeof value.name !== "undefined" ){
            return mapOptions(value);
        }

        const item = items.results.find( x => x.item_number === value);
        if(item){
            return mapOptions(item);
        }
        return null;
      }

}

// export default ItemSelect;

function mapStateToProps(state) {
    return {
        items: state.items.items,
    }
}


function mapDispatchToProps(dispatch) {
    return {
        getItems: bindActionCreators(listItems, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemSelect);
