import React from "react";

export function Loading(){
    return (
        <div className="text-center py-5 opacity-80">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
}