import axios from "axios";
import { toast } from 'react-toastify';
import { softLogoutUser } from "../components/scenes/Auth/actions";
const apiClient = () => {
  const defaultOptions = {
    method: "get",
    headers: {
      "Content-Type": "application/json"
    },
    // withCredentials: true,
    // credentials: 'include',
    crossDomain: true
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? `JWT ${token}` : "";
    return config;
  });

  instance.interceptors.response.use(null, function (error) {
    console.log("theError", error, error.data);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      if (error.response.status === 400) {
        processServerError(error.response.data);
        // console.log('TheError', processedError);
        // toast.error(processedError._error[0])
      } else if (error.response.status === 401) {
        softLogoutUser();
      } else if (error.response.status === 403) {
        toast.warning("Sorry, you have insufficient rights for this action.")
      } else if (error.response.status === 404) {
        toast.error("Sorry, this api endpoint cannot be reached.")
      } else if (error.response.status === 500) {
        toast.warning( error.response.statusText)
      } else {
        toast.warning( error.message + '. View console for more info.')
      }
      return Promise.reject(error.message);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);


    // Something happened in setting up the request that triggered an Error
    console.log("Error", error);
    toast.warning( error.message + '. View console for more info.')

    return Promise.reject(error);
  });

  return instance;
};

export default apiClient();

// util functions
export function processServerError(error) {
  // console.log('processingError', error)
  // console.log('is array', Array.isArray(error))
  // console.log('isobject',  typeof error === 'object',  error.hasOwnProperty('detail'), error.detail )
  if ( Array.isArray(error) ) {
    for (const i of error) {
      console.log('process i ', error, i)
      toast.warning( i );
    }
    return;
  }

  if( typeof error === 'object'){
    if( error.hasOwnProperty('detail') ){
      toast.info( error.detail );
      return
    }
    for (const key in error) {
      if (error.hasOwnProperty(key)) {
        const element = error[key];
        console.log('process', key, element);
        if ( Array.isArray(element) ) {
          for (const i of element) {
            console.log('process i i ', key, element, i)
            toast.warning( key+ ': '+i );
          }
        }else if(String.isString(element)){
          toast.warning( element);
        }else if ( Object.isObject(element)) {
          processServerError(element)
        }else{
          toast.warning('Something unexpected is happening.')
        }
      }
    }
  }

}