import React, {Component} from "react";
import {
  Badge
} from "reactstrap";
class StatusBadge extends Component {
  static defaultProps = {
    className: ''
  }
  render(){
    var classname = 'py-2 px-3 text-uppercase signal-status -'+this.props.status + ' '+ this.props.className;
    const dict = {
      'closed-accepted': 'closed-accepted',
      'closed-declined': 'closed-denied',
      'open-accept': 'open-accept',
      'open-decline': 'open-deny',
      'open-review': 'open-review',
      'open': 'open'
    }
    return (
       <Badge onClick={() => this.props.handleClick() } className={classname}>{dict[this.props.status]}</Badge>
    )
  }
}

export default StatusBadge;
