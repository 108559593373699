import React, { Component } from "react";
import { connect } from "react-redux";

class Redirecting extends Component {

  render() {

    return (
      <div className="page-wrap d-flex flex-row align-items-center py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <div className="mb-4 lead">Redirecting you to {this.props.destination}...</div>
              <div className="text-center py-5 opacity-80">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Redirecting...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>     
    );
  }
}

export default connect(null, null)(Redirecting);

