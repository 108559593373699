import update from 'immutability-helper';
import { toast } from 'react-toastify';
import { CREATE, DELETE, GET, LIST, SYNC, UPDATE } from './actionsTypes';
const centers = {
    items: false,
    isLoading: false,
    isUpdating: false,
    current: false,
    filter: { search: '', limit: 50, ordering: '-name' },
};
export default function reducer(state = centers, action) {
    var indexOfItem = -1;
    switch (action.type) {
        case LIST.FILTER:
            return {...state, filter: action.payload}
        case LIST.LOADING:
        case GET.LOADING:
            return { ...state, isLoading: true };

        case CREATE.LOADING:
            toast.info('Creating center...', {
                toastId: 'create-center',
                autoClose: 1500,
            });
            return { ...state, isUpdating: true };

        case SYNC.LOADING:
            toast.info('Syncing centers from json feed...', {
                toastId: 'sync-center',
                autoClose: 5500,
            });
            return { ...state, isUpdating: true };

        case UPDATE.LOADING:
            toast.info('Updating ' + action.payload.request.data.name + '...', {
                toastId: 'update-center-' + action.payload.request.data.lely_center_id,
                autoClose: 1500,
            });
            return { ...state, isUpdating: true };

        case DELETE.LOADING:
            toast.warning('Deleting center...', {
                toastId: 'delete-center',
                autoClose: 1500,
            });
            indexOfItem = state.items.results.findIndex(x => x.lely_center_id === action.payload.request.lely_center_id);
            console.log(action.payload.lely_center_id, indexOfItem)
            return update(state, {
                isUpdating: { $set: true },
                items: {
                    results: { $unset: [indexOfItem] },
                }
            });

        case LIST.ERROR:
        case GET.ERROR:
        case CREATE.ERROR:
        case UPDATE.ERROR:
        case SYNC.ERROR:
        case DELETE.ERROR:
            return { ...state, isLoading: false, isUpdating: false };

        case LIST.SUCCESS:
            return { ...state, isLoading: false, items: action.payload.data };

        case GET.SUCCESS:
            return { ...state, isLoading: false, current: action.payload.data };


        case CREATE.SUCCESS:
            toast.update('create-center', { render: action.payload.data.name + " created!", type: toast.TYPE.SUCCESS, autoClose: 3500 });
            return { ...state, isLoading: false, isUpdating: false, current: action.payload.data };

        case UPDATE.SUCCESS:
            toast.update('update-center-' + action.payload.data.lely_center_id, { render: action.payload.data.name + " updated!", type: toast.TYPE.SUCCESS, autoClose: 3500 });
            indexOfItem = state.items.results.findIndex(x => x.lely_center_id === action.payload.data.lely_center_id);
            return update(state, {
                items: {
                    results: {
                        [indexOfItem]: {
                            $set: action.payload.data
                        }
                    },
                },
                current: {
                    $set: action.payload.data
                },
                isLoading: { $set: false },
                isUpdating: { $set: false }
            });

        case DELETE.SUCCESS:
            toast.update('delete-center', { render: "Center deleted!", type: toast.TYPE.WARNING, autoClose: 3500 });
            return { ...state, isLoading: false, isUpdating: false, current: action.payload.data };
        case SYNC.SUCCESS:
            toast.update('sync-center', { render: action.payload.data, type: toast.TYPE.SUCCESS, autoClose: 3500 });
            return { ...state, isLoading: false, isUpdating: false, current: action.payload.data };
        default:
            return state;
    }
}
