import React from "react";
import { useSelector } from "react-redux";
import ED from "../img/logo-ed.png";

export default function Footer(){
  const user = useSelector(state => state.auth.user);
  return (
    <div className="footer footer-copyright text-center text-muted py-3">
      {user.center &&
      <small className="px-2">
        Logged in as {user.username} for {user.center.name}
      </small>
      }
      <small className="px-2">
        Powered by
        <img src={ED} alt="Logo Experience Data" className="ml-2 d-inline-block"/> <a className="text-dark stretched-link" href="https://www.experiencedata.nl/">Experience Data</a>
      </small>
    </div>
  )
}
