import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { listCenters } from "../../../../actions/Centers/actions";
import { Loading } from "../../../Helper/Loading";
import Centers from "./components/Centers";
import Filter from "./components/Filter";
import Form from "./components/Form";
export default function LelyCenters(){
  const centers = useSelector(state => state.centers.items)
  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listCenters())
  }, [dispatch]);

  if (!centers) {
    return <Loading></Loading>
  }

  if (params.lely_center_id) {
    return <Form lely_center_id={params.lely_center_id} />
  }

  return (<div> <Filter />  <Centers /></div>)
}

