import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as notifReducer } from "redux-notifications";

// scene reducers
import addClaimReducer from "../components/scenes/AddClaim/reducer";
import authReducer from "../components/scenes/Auth/reducer";
import inboxReducer from "../components/scenes/Inbox/reducer";


// global reducers
import LelyCenterReducer from "../actions/Centers/reducer";
import itemReducer from "../actions/Items/reducer";
import SignalReducer from "../actions/Signal/reducer";




const rootReducer = combineReducers({
  form: formReducer,
  notifs: notifReducer,
  auth: authReducer,
  inbox: inboxReducer,
  centers: LelyCenterReducer,
  signals: SignalReducer,
  addclaim: addClaimReducer,
  items: itemReducer
});

export default rootReducer;
