import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Input, Row } from "reactstrap";
import { bindActionCreators } from 'redux';
import { listItems } from '../../../../../actions/Items/actions';
class Filter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: ''
    };

  }
  updateSearch(search){
    var post = {
      search: search,
      offset: 0,
    };
    this.setState({'search': search});
    return this.props.listItems(post);
  }
  render() {
    return (
            <Row className="py-3 justify-content-between">
             
              <Col sm="8" lg="10">
                <Input
                  type="text"
                  name="search"
                  value={this.state.search}
                  onChange={(e) => {
                    this.updateSearch(e.target.value)
                  }}
                  placeholder="Search (e.g. name or number)"
                />
              </Col>
              <Col sm="4" lg="2" >
                <Link className="btn btn-secondary btn-block" to="/_admin/items/new">Add Item</Link>
              </Col>
            </Row>
    );
  }
}


function mapStateToProps(state) {
  return {
    user: state.auth.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    listItems: bindActionCreators(listItems, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
