import store from '../../store';
import { CREATE, DELETE, GET, LIST, UPDATE, SYNC } from './actionsTypes';


const endpoint = '/lely-centers/'
export function clearFilter(){
  return {
    type: LIST.FILTER,
    payload: {}
  }
}
export function listCenters(filter = {}) {
  const state = store.getState();
  filter = {...state.centers.filter, ...filter};
  return function(dispatch) {
    dispatch({
      type: LIST.FILTER,
      payload: filter
    })
    dispatch( {
      types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
      payload: {
        request: {
          params: filter,
          method: 'GET',
          url: endpoint
        }
      }
    }
    )
  }
}
export function getCenter(lely_center_id) {
  return {
    types: [GET.LOADING, GET.SUCCESS, GET.ERROR],
    payload: {
      request: {
        method: 'GET',
        url: endpoint + lely_center_id + '/'
      }
    }
  }
}
export function createCenter(center) {
  return {
    types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
    payload: {
      request: {
        data: center,
        method: 'POST',
        url: endpoint
      }
    }
  }
}

export function updateCenter(center) {
  return {
    types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
    payload: {
      request: {
        data: center,
        method: 'PUT',
        url: endpoint + center.lely_center_id + '/'
      }
    }
  }
}

export function updateCenterTemp(item){
  return function(dispatch){
    dispatch({
       type: UPDATE.SUCCESS,
       payload: {data: item}
    })
  }
}

export function deleteCenter(id) {
  return {
    types: [DELETE.LOADING, DELETE.SUCCESS, DELETE.ERROR],
    payload: {
      request: {
        method: 'DELETE',
        center_id: id,
        url: endpoint + id + '/'
      }
    }
  }
}

export function syncCenters() {
  return {
    types: [SYNC.LOADING, SYNC.SUCCESS, SYNC.ERROR],
    payload: {
      request: {
        method: 'POST',
        url: endpoint + 'sync/'
      }
    }
  }
}

